<template>
  <div>
    <main class="jobmain">
      <!-- 左边侧边连 -->
      <!-- 右边内容 -->
      <article class="right">
        <p class="" style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">学生学习记录</p>
        <article class="job" style="padding-left: 15px;">
          <!-- 按钮 -->
          <div class="buttons" style="margin-left: 10px;margin-top: 10px">
            <el-button type="primary" icon="el-icon-download" @click="download" v-permission="'eduStudentStudyRecord:export'">导出</el-button>
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
          </div>
          <el-form :model="search" class="demo-ruleForm" inline style="margin-top: 1rem;width:100%; margin-left: 10px">

            <el-form-item  label="课程名称/课程编号" style="margin-bottom: 0">
              <div>
                <el-input style="width: 220px"  placeholder="课程名称/课程编号" v-model="search.name" class="input-with-select">
                </el-input>
              </div>
            </el-form-item>
            <el-form-item  label="函授站" style="margin-bottom: 0; margin-left: 14px">
              <el-select
                  style="width: 220px"
                  @change="handleSearch"
                  clearable
                  v-model="search.siteId"
                  placeholder="请选择"
                  class="el-form-input-width"
              >
                <el-option
                    v-for="item in site"
                    :key="item.id"
                    :label="item.siteName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <!-- 表格 -->
          <section class="tables">
            <el-table
                border
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                highlight-current-row
                :default-sort="{ prop: 'date', order: 'descending' }"
                style="width: 100%"
                size="medium"
                height="580px"
                max-height="580px"
                :header-cell-style="{'text-align':'center'}"
                :cell-style="{'text-align':'center'}"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <!-- 序号 -->
              <el-table-column type="index"
                               label="序号"
                               width="80">
              </el-table-column>
              <!-- 学生学号 -->
              <el-table-column
                  property="studentNo"
                  label="学生学号"
                  sortable
              >
              </el-table-column>
              <!-- 学生姓名 -->
              <el-table-column
                  property="name"
                  label="学生姓名"
                  sortable
              >
              </el-table-column>
              <!-- 课程名称 -->
              <el-table-column
                  property="courseName"
                  show-overflow-tooltip
                  label="课程名称"
                  sortable
              >
              </el-table-column>
              <!-- 课程编号 -->
              <el-table-column
                  property="courseCode"
                  show-overflow-tooltip
                  label="课程编号"
                  sortable
              >
              </el-table-column>
              <!-- 单元名称 -->
              <el-table-column
                  property="unitName"
                  label="单元名称"
                  sortable
              >
              </el-table-column>
              <!-- 视频开始时间 -->
              <el-table-column
                  property="videoStartSecond"
                  label="视频开始时间(秒)"
                  sortable
              >
              </el-table-column>
              <!-- 视频结束时间(小时) -->
              <el-table-column
                  property="videoEndSecond"
                  label="视频结束时间(秒)"
                  sortable
              >
              </el-table-column>
              <!-- 学习时长 -->
              <el-table-column
                  property="studySecond"
                  label="学习时长(秒)"
                  sortable
              >
              </el-table-column>
              <!-- 开始学习时间 -->
              <el-table-column
                  property="studyStartTime"
                  show-overflow-tooltip
                  label="开始学习时间"
                  sortable
              >
              </el-table-column>
              <!-- 结束学习时间 -->
              <el-table-column
                  property="studyEndTime"
                  show-overflow-tooltip
                  label="结束学习时间"
                  sortable
              >
              </el-table-column>
              <!-- 浏览器-->
              <el-table-column
                  property="browser"
                  label="浏览器"
                  sortable
              >
              </el-table-column>
              <!-- 浏览器版本-->
              <el-table-column
                  property="browserEdition"
                  label="浏览器版本"
                  sortable
              >
              </el-table-column>
              <!-- 操作系统-->
              <el-table-column
                  property="operatingSystem"
                  label="操作系统"
                  sortable
              >
              </el-table-column>
            </el-table>
          </section>
          <!-- 分页 -->
          <section class="paging">
            <div class="block">
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="search.page"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="search.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.total"
              >
              </el-pagination>
            </div>
          </section>
        </article>
      </article>
    </main>
  </div>
</template>
<script>
//import "bootstrap/dist/css/bootstrap.min.css";
//import "bootstrap/dist/js/bootstrap.min.js";
export default {
  created() {
    this.queryEduSite();
    this.handleSearch();
  },
  data() {
    return {
      input: "", //输入框
      total: 0, //输入框
      select: "", //下拉框
      selectfist: "", //年级下拉框
      hierarchy: "", //层次下拉框
      major: "", //专业
      value4: "", //开始日期
      value5: "", //结束日期
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      currentPage4: 4,
      tableData: [], //表格
      site: [],
      search:{
        name:"",
        siteId:"",
        page:1,
        pageSize:10,
      },
      currentRow: null,
    };
  },
  methods: {
    handleSearch() {
      this.getPermission('eduFeeTuition:list', () => {
            this.openLoadingView()
      let fromData = new FormData();
      fromData.append("siteId",this.search.siteId);
      fromData.append("info",this.search.name);
      fromData.append("page",this.search.page);
      fromData.append("pageSize",this.search.pageSize);
      this.$axios_supermall.post("/hnjxjy-core/eduStudentStudyRecord/list",fromData).then(resp=>{
        this.tableData = resp.data.data.list; //表格列表
        this.total = resp.data.data.total;
      })
      });
    },
    //查询函授站下拉
    queryEduSite() {
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post("/hnjxjy-core/eduSite/queryAllSite", formData).then(resp => {
        if (resp.data.code == 2000) {
          this.site = resp.data.data;
        }
      })
    },
    download() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduStudentStudyRecord/export",
        responseType: "blob",
        headers: objPar,
        params: {
          "siteId":this.search.siteId,
          "info":this.search.name,
        },
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleChange(val) {
    },
  },
};
</script>
<style scoped>
html {
  font-size: calc(100vw / 7.5);
}
.jobmain {
  display: flex;
}
/* 右边 */
.right {
  width: 100%;
  padding: 0.1rem;
}
/* 标题 */
.test {
  text-align: left;
  font-size: 1.2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: 0.2rem;
  border-bottom: 0.01rem solid rgb(221, 221, 221);
}
/* 按钮 */
.buttons {
  display: flex;
}
/* form表单 */
.forms {
  display: flex;
  flex-wrap: wrap;
}
.forms section {
  width: 25%;
}
/* 学期 */
.semester {
  font-size: 1rem;
  margin-bottom: 0;
  padding: 0.5rem;
}
.searchs {
  margin-right: 1.3rem;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}
/* 表格 */
.tables {
  margin-top: 1rem;
}
/* 多余内容呈现... */
.dian {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
/* 分页 */
/* .paging {
  margin-top: 1rem;
  width: 100%;
  overflow-x: scroll;
  display: flex;
}
.paging::-webkit-scrollbar {
  width: 0;
  height: 0;
} */

@media (max-width: 75rem) {
  .left {
    display: none;
  }
  .job{

  }
  .rigtht {
    width: 100%;
  }
  .forms section {
    width: 100%;
  }
  .row {
    margin-left: -15px;
  }
  .semester {
    margin-left: 1rem;
    padding: 0.5rem 0;
  }
  .block {
    margin-left: 1rem;
  }
}
</style>
