import { render, staticRenderFns } from "./studyRecord.vue?vue&type=template&id=96917958&scoped=true"
import script from "./studyRecord.vue?vue&type=script&lang=js"
export * from "./studyRecord.vue?vue&type=script&lang=js"
import style0 from "./studyRecord.vue?vue&type=style&index=0&id=96917958&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96917958",
  null
  
)

export default component.exports