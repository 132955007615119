<template>
    <div>
        <div>
            <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
                权限设置
            </div>
            <div class="table-wrap" style="margin-top: 1rem">
                <div style="
            width: 97%;
            display: inline-block;
            background-color: #fff;
            float: left;
          ">
                    <div class="fistli"
                         style="border-bottom: 1px solid #eee; font-size: 16px">
                        菜单
                        <div style="font-size: 13px; color: skyblue; float: right"
                             v-permission="'sysmenu:insert'"
                             @click="addGrade">
                            <i class="el-icon-plus"
                               style="color: skyblue; font-size: 14px">
                            </i>
                            新增一级菜单
                        </div>
                        <!--                        <div style="font-size: 13px; color: skyblue; float: right"-->
                        <!--                             @click="moveMenu">-->
                        <!--                            <i class="el-icon-plus"-->
                        <!--                               style="color: skyblue; font-size: 14px">-->
                        <!--                            </i>-->
                        <!--                            移动菜单-->
                        <!--                        </div>-->
                    </div>
                    <div class="infinite-list" style="overflow:auto;height: 700px">
                        <el-table
                                :data="menuList"
                                style="width: 100%; margin-bottom: 20px"
                                row-key="id"
                                border
                                default-expand-all
                                :tree-props="{ children: 'sysMenuVos', hasChildren: 'hasSysMenuVos' }">
                            <el-table-column style="" label="菜单">
                                <template slot-scope="scope" style="">
                                    <el-link @click="editorMenu(scope.row, false)">{{scope.row.menuName}}</el-link>
                                </template>
                            </el-table-column>
                            <el-table-column align="right" label="操作">
                                <template slot-scope="scope">
                                    <el-button-group style="margin-left: 540px">
                                        <el-button type="primary" size="mini" icon="el-icon-plus"
                                                   v-permission="'hnjxjy_sys_children'"
                                                   @click="childcai(scope.row)">
                                        </el-button>
                                        <el-button type="primary" size="mini" icon="el-icon-s-operation"
                                                   v-permission="'hnjxjy_sys_menur_pes'"
                                                   @click="editorMenu(scope.row, false,1)">
                                        </el-button>
                                        <el-button type="danger" size="mini" icon="el-icon-delete"
                                                   v-permission="'sysmenu:deleted'"
                                                   @click="deletedServiceMenu(scope.row)">
                                        </el-button>
                                    </el-button-group>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <el-dialog
                        :visible.sync="menuFormVisible"
                        width="30%">
                    <el-card
                            class="box-card"
                            style="">
                        <div slot="header" class="clearfix" style="height: 9px">
                            <div style="float: left; padding: 3px 0">{{menuFormDataTitle}}</div>
                            <el-button style="
                float: right;
                padding: 3px 0;
                color: #9c9b9b;
                font-size: 14px;
              "
                                       type="text"
                                       icon="el-icon-close"
                                       @click="menuFormCancel">
                                取消
                            </el-button>
                            <el-button
                                    style="
                float: right;
                padding: 3px 0;
                color: skyblue;
                font-size: 14px;
              "
                                    type="text"
                                    icon="el-icon-check"
                                    @click="menuFormSave">
                                提交
                            </el-button>
                        </div>
                        <el-form
                                ref="elForm"
                                :model="menuFormData"
                                :rules="rules"
                                size="medium"
                                label-width="100px"
                                style="margin-top: 5px">
                            <el-row>
                                <template v-if="parentMenuFormData.id > 0">
                                    <el-form-item label="上级菜单" prop="menuNameParent">
                                        <el-input
                                                :disabled="true"
                                                v-model="parentMenuFormData.menuName"
                                                placeholder="请输入上级菜单"
                                                clearable
                                                :style="{ width: '90%' }">
                                        </el-input>
                                    </el-form-item>
                                </template>
                                <el-form-item label="菜单名称" prop="menuName">
                                    <el-input
                                            v-model="menuFormData.menuName"
                                            placeholder="请输入菜单名称"
                                            clearable
                                            :style="{ width: '90%' }"
                                    >
                                    </el-input>
                                </el-form-item>

                                <el-form-item label="菜单url" prop="menuUrl">
                                    <el-input
                                            v-model="menuFormData.menuUrl"
                                            placeholder="请输入菜单url"
                                            clearable
                                            :style="{ width: '90%' }"
                                    >
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="菜单排序" prop="sort">
                                    <el-input-number
                                            v-model="menuFormData.sort"
                                            placeholder="排序"
                                            :step="1">
                                    </el-input-number>
                                </el-form-item>
                                <template v-if="false">
                                    <el-form-item label="菜单类型" prop="isLeaf">
                                        <el-radio-group v-model="menuFormData.isLeaf" size="medium">
                                            <el-radio
                                                    v-for="(item, index) in menuTypeOption"
                                                    :key="index"
                                                    :label="item.value"
                                                    :disabled="item.disabled"
                                            >{{ item.label }}
                                            </el-radio
                                            >
                                        </el-radio-group>
                                    </el-form-item>
                                </template>
                                <el-form-item label="菜单图标" prop="menuIcon">
                                    <el-upload
                                            ref="upload"
                                            action=""
                                            :on-change="menuIconChange"
                                            :auto-upload="false"
                                            :file-list="menuIconList"
                                            list-type="picture">
                                        <el-button
                                                slot="trigger"
                                                size="small"
                                                type="primary"
                                                icon="el-icon-upload"
                                        >选取文件
                                        </el-button>
                                    </el-upload>
                                </el-form-item>
                                <el-form-item label="显示/隐藏" prop="show">
                                    <el-radio-group v-model="menuFormData.show" size="medium">
                                        <el-radio
                                                v-for="(item, index) in menuShowOption"
                                                :key="index"
                                                :label="item.value"
                                                :disabled="item.disabled"
                                        >{{ item.label }}
                                        </el-radio>
                                    </el-radio-group>
                                </el-form-item>

                                <el-form-item label="状态" prop="state">
                                    <el-radio-group v-model="menuFormData.state" size="medium">
                                        <el-radio
                                                v-for="(item, index) in menuStateOption"
                                                :key="index"
                                                :label="item.value"
                                                :disabled="item.disabled">
                                            {{ item.label }}
                                        </el-radio>
                                    </el-radio-group>
<!--                                    {{menuFormData.id}}-->
                                </el-form-item>

                                <el-form-item label="菜单标识" prop="state"
                                              v-if="menuFormData.id ==0 || menuFormData.id ==null">
                                    <el-select v-model="menuFormData.menuType" placeholder="请选择标识">
                                        <el-option
                                                v-for="item in [{label:'院校菜单',value:0},{label:'教师菜单',value:1},{label:'学生菜单',value:2},{label:'好客联盟',value:3}]"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-row>
                        </el-form>
                    </el-card>

                    <!--                <span slot="footer" class="dialog-footer">-->
                    <!--                  <el-button @click="dialogVisible = false">取 消</el-button>-->
                    <!--                  <el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
                    <!--                </span>-->
                </el-dialog>
                <el-dialog
                        title="提示"
                        :visible.sync="menuFormVisible2"
                        width="50%">
                    <div class="infinite-list" style="overflow:auto;height: 300px">
                        <div style="font-size: 13px;color: skyblue;background-color: #fff;height: 15px;"
                             v-permission="'hnjxjy_sys_menur_inspes'"
                             @click="resources">
                            <div style="float: right; cursor: pointer">
                                <i class="el-icon-plus" style="color: skyblue; font-size: 14px"></i>
                                新增权限资源
                            </div>
                        </div>
                        <el-table
                                ref="multipleTable"
                                :data="menuFormData.sysPermissions"
                                border>
                            <el-table-column label="ID" prop="id"></el-table-column>
                            <el-table-column label="权限标识" prop="permission"></el-table-column>
                            <el-table-column label="名称" prop="name"></el-table-column>
                            <el-table-column
                                    prop="isBtn"
                                    label="标签"
                                    width="100"
                                    :filters="[ { text: '按钮', value: true },{ text: '非按钮', value: false }]"
                                    :filter-method="filterTag"
                                    filter-placement="bottom-end">
                                <template slot-scope="scope">
                                    <el-tag
                                            :type="scope.row.isBtn ? 'primary' : 'success'"
                                            disable-transitions>
                                        <template v-if="scope.row.isBtn">按钮</template>
                                        <template v-if="!scope.row.isBtn">非按钮</template>
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" fixed="right">
                                <template slot-scope="scope">
                                    <el-button
                                            v-permission="'hnjxjy_sys_menur_updpes'"
                                            @click="editorPermission(scope.row)"
                                            type="primary"
                                            size="small">
                                        编辑
                                    </el-button>
                                    <el-button
                                            v-permission="'sysmenu:deleted'"
                                            type="danger"
                                            size="small"
                                            @click="delPermission(scope.row)">
                                        删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="menuFormVisible2 = false">确 定</el-button>
                    </div>
                </el-dialog>
            </div>
        </div>

        <!-- 新增资源 -->
        <el-dialog title="新增权限资源" :visible.sync="resourceVisible" width="30%">
            <el-form
                    ref="permissionForm"
                    :model="permissionFormData"
                    :rules="permissionRules"
                    size="medium"
                    label-width="100px"
                    label-position="left"
            >
                <el-form-item label="菜单名称">
                    <el-input
                            v-model="menuFormData.menuName"
                            placeholder="请输入菜单名称"
                            disabled
                            clearable
                            :style="{ width: '100%' }"
                    ></el-input>
                </el-form-item>
                <el-form-item label="权限名称" prop="name">
                    <el-input
                            v-model="permissionFormData.name"
                            placeholder="请输入权限名称"
                            clearable
                            :style="{ width: '100%' }"
                    ></el-input>
                </el-form-item>
                <el-form-item label="权限标识" prop="permission">
                    <el-input
                            v-model="permissionFormData.permission"
                            placeholder="请输入权限标识"
                            clearable
                            :style="{ width: '100%' }"
                    >
                    </el-input>
                </el-form-item>
              <el-form-item label="路由" prop="menUrl">
                <el-input
                    v-model="permissionFormData.menUrl"
                    placeholder="请输入路由地址"
                    clearable
                    :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
                <el-form-item label="是否按钮" prop="isBtn">
                    <el-radio-group v-model="permissionFormData.isBtn" size="medium">
                        <el-radio
                                v-for="(item, index) in permissionIsBtnOptions"
                                :key="index"
                                :label="item.value"
                                :disabled="item.disabled"
                        >{{ item.label }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item size="large">
                    <el-button type="primary" @click="permissionSubmitForm"
                    >提交
                    </el-button
                    >
                    <el-button @click="permissionResetForm">重置</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>


    export default {

        data() {
            return {
                menuList: [],
                uploadFile: {},
                filemenuIcon: {},

                menuFormVisible: false,
                menuFormVisible2: false,
                resourceVisible: false,
                tableData: [
                    {
                        id: "CREATE",
                        name: "新增",
                        num: "123",
                        sex: "男",
                        idCard: "3201",
                        phone: "13333333333",
                        address: "上海市普陀区金沙江路 1518 弄",
                        memo: "备注",
                    },
                    {
                        id: "UPDATE",
                        name: "修改",
                        num: "123",
                        sex: "男",
                        idCard: "3201",
                        phone: "13333333333",
                        address: "上海市普陀区金沙江路 1518 弄",
                        memo: "备注",
                    },
                    {
                        id: "DELETE",
                        name: "删除",
                        num: "123",
                        sex: "男",
                        idCard: "3201",
                        phone: "13333333333",
                        address: "上海市普陀区金沙江路 1518 弄",
                        memo: "备注",
                    },
                ],
                menuFormDataTitle: "新增一级菜单",
                menuFormData: {
                    id: 0,
                    file: "",
                    menuName: "",
                    menuIcon: "",
                    menuUrl: "",
                    sort: 1,
                    show: 1,
                    state: 1,
                    menuType: 0
                },
                parentMenuFormData: {
                    id: 0,
                    file: "",
                    menuName: "",
                    menuIcon: "",
                    menuUrl: "",
                    sort: 1,
                    show: 1,
                    state: 1,
                },
                permissionFormData: {
                    id: 0,
                    name: "",
                    permission: "",
                    isBtn: "",
                    menuId: "",
                },
                menuIconList: [],
                rules: {
                    field102: [],
                    field108: [],
                    field111: [],
                    field104: [],
                    field105: [],
                    field106: [],
                },
                menuTypeOption: [
                    {
                        label: "新版",
                        value: 1,
                    },
                    {
                        label: "旧版",
                        value: 2,
                    },
                ],
                menuStateOption: [
                    {
                        label: "启用",
                        value: 1,
                    },
                    {
                        label: "停用",
                        value: 0,
                    },
                ],
                menuShowOption: [
                    {
                        label: "显示",
                        value: 1,
                    },
                    {
                        label: "隐藏",
                        value: 0,
                    },
                ],
                permissionFormData: {
                    name: "",
                    permission: "",
                    isBtn: 1,
                    menuname: "",
                  menUrl: "",
                },
                permissionRules: {
                    name: [
                        {
                            required: true,
                            message: "请输入权限名称",
                            trigger: "blur",
                        },
                    ],
                    permission: [
                        {
                            required: true,
                            message: "请输入权限标识",
                            trigger: "blur",
                        },
                    ],
                    isBtn: [
                        {
                            required: true,
                            message: "是否按钮不能为空",
                            trigger: "change",
                        },
                    ],
                  menUrl: [
                    {
                      required: true,
                      message: "路由不能为空",
                      trigger: "change",
                    },
                  ],
                    menuname: [],
                },
                permissionIsBtnOptions: [
                    {
                        label: "是",
                        value: 1,
                    },
                    {
                        label: "否",
                        value: 0,
                    },
                ],
            };
        },
        created() {
            this.getMenuList();
        },
        methods: {
            moveMenu() {
                let list = [197];
                list.forEach(l => {
                    let formData = new FormData();
                    formData.append('sysMenuId', 1);
                    formData.append('pMenuId', 232);//目标菜单
                    formData.append('id', l);//移动菜单id
                    this.$axios_supermall.post('/hnjxjy-admin/sysMenu/removeMenu?sysMenuId=1', formData)
                })

            },
            addGrade() {
                this.menuFormDataTitle = "新增一级菜单";
                this.menuFormVisible = true;
                this.menuFormVisible2 = false;
                this.menuFormData.menuName = "";
                this.menuFormData.menuUrl = "";
                this.menuFormData.menuType = 0;
                this.menuFormData.sysPermissions = [];
                this.parentMenuFormData.id = 0;
                this.menuFormData.id = 0;
            },
            childcai(item) {
                this.menuFormDataTitle = "新增子菜单";
                this.menuFormData.menuName = "";
                this.menuFormData.menuUrl = "";
                this.menuFormData.sysPermissions = [];
                // this.menuFormData.id = -1;
                this.menuFormVisible = true;
                this.menuFormVisible2 = false;
                this.parentMenuFormData = JSON.parse(JSON.stringify(item));
            },
            // 点击修改
            editorMenu(item, pitem, type) {
                if (this.getPermission('sysmenu:update',()=>{
                    this.menuFormDataTitle = "编辑菜单";
                    this.menuIconList = Array();
                    let icon = new Object();
                    icon.name = "";
                    icon.url = item.menuIcon;
                    this.menuIconList.push(icon);
                    if (pitem) {
                        this.parentMenuFormData = JSON.parse(JSON.stringify(pitem));
                    } else {
                        this.parentMenuFormData.id = 0;
                        this.parentMenuFormData.menuName = "";
                        this.parentMenuFormData.menuUrl = "";
                        this.parentMenuFormData.sysPermissions = [];
                    }
                    this.menuFormData = JSON.parse(JSON.stringify(item));
                    // this.menuFormData.id = -1;
                    if (type == null) {
                        this.menuFormVisible = true;
                    } else if (type == 1) {
                        this.menuFormVisible2 = true;
                    }
                }));
            },
            //新增资源
            resources() {
                this.permissionFormData.id = 0;
                this.permissionFormData.name = "";
                this.permissionFormData.permission = "";
                this.resourceVisible = true;
            },
            // 点击修改
            editorPermission(row) {
                this.permissionFormData = JSON.parse(JSON.stringify(row));
                this.resourceVisible = true;
            },
            delPermission(row) {
                let that = this;
                this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                let param = new Object();
                param.id = row.id;
                this.$confirm("此操作将永久删除, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        that.$axios
                            .post(
                                "hnjxjy-admin/sysPermission/deletedServicePermission",
                                param,
                                {
                                    headers: that.loginList,
                                }
                            )
                            .then((res) => {
                                if (res.data.code !== 2000) {
                                    // that.$message({
                                    //     message: res.data.message,
                                    //     type: "warning",
                                    // });
                                    return;
                                }
                                that.$message({
                                    message: res.data.message,
                                    type: "success",
                                });
                                that.getMenuList();
                                that.queryMenuByMenuId();
                            });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
            menuFormCancel() {
                this.menuFormVisible = false;
                this.menuFormVisible2 = false;
                this.$refs["elForm"].resetFields();
            },
            menuFormSave() {
                this.addSysMenu();
            },
            filterTag(value, row) {
                return row.isBtn === value;
            },
            getMenuList() {
                this.getPermission('sysmenu:list', () => {
                    this.openLoadingView()
                    let that = this;
                    this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                    this.$axios({
                        url: "hnjxjy-admin/sysPermission/queryAllSysPermissionTwo",
                        headers: this.loginList,
                    }).then((res) => {
                        if (res.data.code !== 2000) {
                            // that.$message({
                            //     message: res.data.message,
                            //     type: "warning",
                            // });
                            return;
                        }
                        that.menuList = res.data.data;
                        this.loadingView.close()
                    });
                });
            },
            queryMenuByMenuId() {
                let that = this;
                this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                this.$axios({
                    url: "hnjxjy-admin/sysMenu/queryMenuByMenuId?id=" + that.menuFormData.id,
                    headers: this.loginList,
                }).then((res) => {
                    if (res.data.code !== 2000) {
                        // that.$message({
                        //     message: res.data.message,
                        //     type: "warning",
                        // });
                        return;
                    }
                    that.menuFormData = res.data.data;
                });
            },
            addSysMenu() {
                let that = this;
                this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                let opturl = "addSysMenu"; //添加菜单
                var param = new FormData(); // FormData 对象
                param.append("file", this.uploadFile); // 文件对象
                param.append("menuName", this.menuFormData.menuName); // 其他参数
                param.append("menuUrl", this.menuFormData.menuUrl); // 其他参数
                param.append("sort", this.menuFormData.sort); // 其他参数
                param.append("show", this.menuFormData.show ? 1 : 0); // 其他参数
                param.append("state", this.menuFormData.state); // 其他参数
                if (this.menuFormData.menuType != null && this.menuFormData.menuType != '') {
                    param.append("menuType", this.menuFormData.menuType); // 其他参数
                }
                if (this.parentMenuFormData.id > 0) {
                    param.append("pMenuId", this.parentMenuFormData.id);
                }
                if (this.menuFormData.id > 0) {
                    param.append("id", this.menuFormData.id); // 有id，修改菜单
                    opturl = "updateServiceMenu";
                }
                this.$axios
                    .post("hnjxjy-admin/sysMenu/" + opturl, param, {
                        headers: that.loginList,
                    })
                    .then((res) => {
                        if (res.data.code !== 2000) {
                            // that.$message({
                            //     message: res.data.message,
                            //     type: "warning",
                            // });
                            return;
                        } else {
                            that.$message({
                                message: res.data.message,
                                type: "success",
                            });
                            this.menuFormVisible = false;
                            this.menuFormVisible2 = false;
                            that.getMenuList();
                        }
                    });
            },
            deletedServiceMenu(item) {
                let that = this;
                this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                this.$confirm("此操作将永久删除, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        that
                            .$axios({
                                url:
                                    "hnjxjy-admin/sysMenu/deletedServiceMenu?sysMenuId=" + item.id,
                                headers: that.loginList,
                            })
                            .then((res) => {
                                if (res.data.code !== 2000) {
                                    // that.$message({
                                    //     message: res.data.message,
                                    //     type: "warning",
                                    // });
                                    return;
                                }
                                that.$message({
                                    message: res.data.message,
                                    type: "success",
                                });
                                that.getMenuList();
                                that.queryMenuByMenuId();
                            });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
            menuIconChange(file, fileList) {
                this.uploadFile = file.raw;
            },
            permissionSubmitForm() {
                let that = this;
                this.$refs["permissionForm"].validate((valid) => {
                    if (!valid) return;
                    // TODO 提交表单
                    that.insertServicePermission();
                });
            },
            permissionResetForm() {
                this.$refs["permissionForm"].resetFields();
            },
            insertServicePermission() {
                let that = this;
                this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                let opturl = "insertServicePermission"; //添加菜单权限

                that.permissionFormData.menuId = that.menuFormData.id;
                if (this.permissionFormData.id > 0) {
                    opturl = "updateServicePermission";
                }
                this.$axios
                    .post("hnjxjy-admin/sysPermission/" + opturl, that.permissionFormData, {
                        headers: that.loginList,
                    })
                    .then((res) => {
                        if (res.data.code !== 2000) {
                            // that.$message({
                            //     message: res.data.message,
                            //     type: "warning",
                            // });
                            return;
                        } else {
                            that.$message({
                                message: res.data.message,
                                type: "success",
                            });
                            that.resourceVisible = false;
                            that.$refs["permissionForm"].resetFields();
                            that.getMenuList();
                            that.queryMenuByMenuId();
                        }
                    });
            },
        },
    };
</script>
<style scoped>
    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding: 1rem;
    }

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    .rigth {
        width: 93%;
        height: 870px;
        overflow-y: scroll;
        display: inline-block;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    ul {
        list-style: none;
    }

    .fistli {
        padding: 0.5rem 1rem;
        text-align: left;
        font-size: 14px;
        cursor: pointer;
    }

    .fistli1 {
        padding: 0.8rem 1rem;
        text-align: left;
        font-size: 14px;
        cursor: pointer;
    }

    .portrait_Sprite {
        padding: 0.4rem;
        display: inline-block;
        background-color: skyblue;
        color: #fff;
        border-radius: 10%;
        font-size: 14px;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 56px;
        height: 56px;
        line-height: 56px;
        text-align: center;
    }
</style>
