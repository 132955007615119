<template>
    <div>
        <div>
            <!-- 标题 -->
            <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
                系统用户
            </div>
            <!-- input框 -->
            <div class="search">
                <el-form class="demo-ruleForm" inline>
                    <el-form-item style="margin-bottom: 0">
                        <el-input placeholder="登录账号" prefix-icon="el-icon-user" v-model="userListSearch.loginAccount">
                        </el-input>
                    </el-form-item>
                    <el-form-item style="margin-bottom: 0">
                        <el-input placeholder="手机号" prefix-icon="el-icon-mobile-phone" v-model="userListSearch.phone">
                        </el-input>
                    </el-form-item>
                    <el-form-item style="margin-bottom: 0">

                        <el-select
                                v-model="userListSearch.accountType"
                                placeholder="请选择账号类型"
                                clearable
                                :style="{ width: '100%' }"
                        >
                            <el-option
                                    v-for="(item, index) in accountTypeOptions"
                                    :key="index"
                                    :label="item.label"
                                    :value="item.value"
                                    :disabled="item.disabled"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item style="margin-bottom: 0">
                        <el-button type="primary" icon="el-icon-search" @click="searchUser">搜索</el-button>
                        <el-button icon="el-icon-edit" type="primary" v-permission="'sysUser:insert'" @click="addUser"
                        >添加
                        </el-button
                        >
                    </el-form-item>
                </el-form>
            </div>
            <!-- 表格 -->
            <div class="table-wrap">
                <el-table :data="userList" border
                          size="medium"
                          height="630"
                          max-height="630"
                          :header-cell-style="{'text-align':'center'}"
                          :cell-style="{'text-align':'center'}"
                >
                    <el-table-column label="用户名" prop="loginAccount">
                    </el-table-column>
                    <el-table-column label="昵称" prop="nikName"></el-table-column>
                    <el-table-column label="手机号码" prop="contactPhone">
                    </el-table-column>
                    <!-- <el-table-column label="邮箱" prop="email"></el-table-column> -->
                    <el-table-column label="用户类型" prop="accountTypeName">
                    </el-table-column>
                    <el-table-column label="最近登录时间">
                        <template slot-scope="scope">
                            <span>{{ scope.row.lastLoginTime }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="创建时间">
                        <template slot-scope="scope">
                            <span>{{ scope.row.createTime }}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button
                                    v-permission="'sysUser:insertRole'"
                                    @click="bindRole(scope.row)"
                                    type="primary"
                                    size="small"
                            >绑定角色
                            </el-button
                            >
                        </template>
                    </el-table-column> -->
                </el-table>
                <div class="pagin" style="padding: 1rem">
                    <el-pagination
                            @size-change="sysPageChangeSize"
                            @current-change="sysCurrentChange"
                            :current-page="userListParm.page"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="userListParm.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totals"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>

        <!-- 绑定角色 -->
        <el-dialog title="绑定角色" :visible.sync="bindRuleDialogVisible" width="40%">
            <div>
                <el-table
                        :data="roleList"
                        border
                        style="width: 100%"
                        highlight-current-row
                        size="medium"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                >
                    <el-table-column prop="name" label="角色名称" width="250">
                    </el-table-column>
                    <el-table-column prop="name" label="角色描述"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button
                                    size="mini" type="primary" plain
                                    @click="doBindRole(scope.$index, scope.row)"
                            >确认绑定
                            </el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <div style="padding: 1rem"></div>
            </div>
        </el-dialog>
        <div>
            <el-dialog
                    title="添加用户"
                    :visible.sync="userFormDialogVisible"
                    :before-close="beforeClose"
                    style="padding: 30px">
                <el-form
                        inline
                        class="inlineForm"
                        ref="elForm"
                        :model="userFormData"
                        :rules="userFormRules"
                        size="medium"
                        label-width="150px"
                        label-position="left"
                >
                    <el-form-item label="登录账号/院校编号" prop="loginAccount">
                        <el-input
                                v-model="userFormData.loginAccount"
                                placeholder="请输入登录账号/院校编号"
                                clearable
                                :style="{ width: '100%' }">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="账号类型" prop="accountType">
                        <el-select
                                v-model="userFormData.accountType" disabled
                                placeholder="请选择账号类型"
                                clearable
                                :style="{ width: '100%' }"
                        >
                            <el-option
                                    v-for="(item, index) in accountTypeOptions"
                                    :key="index"
                                    :label="item.label"
                                    :value="item.value"
                                    :disabled="item.disabled"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="密码" prop="loginPassword">
                        <el-input
                                v-model="userFormData.loginPassword"
                                placeholder="请输入密码"
                                clearable
                                show-password
                                :style="{ width: '100%' }"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="站点名称" prop="siteName">
                        <el-input
                                v-model="userFormData.siteName"
                                placeholder="请输入站点名称"
                                clearable
                                :style="{ width: '100%' }">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="依托单位" prop="supportUnit">
                        <el-input
                                v-model="userFormData.supportUnit"
                                placeholder="请输入依托单位"
                                clearable
                                :style="{ width: '100%' }">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="单位性质" prop="supUnitNature">
                        <el-select
                                v-model="userFormData.supUnitNature"
                                placeholder="请选择单位性质"
                                clearable
                                :style="{ width: '100%' }"
                        >
                            <el-option
                                    v-for="(item, index) in supUnitNatureOptions"
                                    :key="index"
                                    :label="item.label"
                                    :value="item.value"
                                    :disabled="item.disabled">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="省" prop="areaId">
                        <el-select
                                v-model="userFormData.areaId"
                                placeholder="请选择城市区域"
                                @change="cityHandleChange1"
                        >
                            <el-option
                                    v-for="item in citynameOptions1"
                                    :key="item.id"
                                    :label="item.areaName"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="市" prop="cityId">
                        <el-select
                                v-model="userFormData.cityId"
                                placeholder="请选择城市区域"
                                @change="cityHandleChange2"
                        >
                            <el-option
                                    v-for="item in citynameOptions2"
                                    :key="item.id"
                                    :label="item.areaName"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="区/县" prop="districtId">
                        <el-select
                                v-model="userFormData.districtId"
                                placeholder="请选择城市区域"
                                @change="cityHandleChange3"
                        >
                            <el-option
                                    v-for="item in citynameOptions3"
                                    :key="item.id"
                                    :label="item.areaName"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="手机号" prop="phone" required>
                        <el-input v-model="userFormData.phone" oninput="value=value.replace(/[^0-9.]/g,'')"
                                  maxlength="11"
                                  placeholder="请输入手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="院校logo" prop="file" required>
                        <el-upload
                                ref="upload"
                                action="#"
                                class="avatar-uploader"
                                :on-change="fileuploadChange"
                                :auto-upload="false"
                                :show-file-list="false"
                                :file-list="fileList">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"
                               style=" border: 1px dashed #d9d9d9;">
                            </i>
                          <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，推荐尺寸75 x 75</div>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <div slot="footer">
                    <el-button @click="userFormDialogVisibleFalse">取消</el-button>
                    <el-button type="primary" @click="userFormSubmit">确定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>


    export default {

        data() {
          let checkTel = (rule, value, callback) => {
            if (!value) {
              return callback(new Error('手机号不能为空'));
            } else {
              const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
              if (reg.test(value)) {
                callback();
              } else {
                return callback(new Error('请输入正确的手机号'));
              }
            };
          }
            return {
              //验证手机号
                // strage
                loginList: {},
                fileList: [],
                // 初始化数据
                userList: [],
                title: "系统用户",
                binduser: {},
                // 分页器
                userListSearch: {
                    accountType: "",
                    loginAccount: "",
                    phone: "",
                },
                totals: 10,
                userListParm: {
                    page: 1,
                    pageSize: 10,

                },
                //  绑定角色对话框
                bindRuleDialogVisible: false,
                // 绑定角色table
                roleList: [],
                userFormDialogVisible: false,
                userFormData: {
                    loginAccount: "",
                    accountType: 4,
                    loginPassword: "",
                    supUnitNature: "",
                    siteName: "",
                    supportUnit: "",
                    areaId: "",
                    cityId: "",
                    districtId: "",
                    file: null,
                    phone: ''
                },
                userFormRules: {
                    loginAccount: [
                        {
                            required: true,
                            message: "请输入登录账号",
                            trigger: "blur",
                        },
                    ],
                    phone: [
                      { validator: checkTel, trigger: 'blur' }
                    ],
                    accountType: [
                        {
                            required: true,
                            message: "请选择账号类型",
                            trigger: "change",
                        },
                    ],
                    loginPassword: [
                        {
                            required: true,
                            message: "请输入密码",
                            trigger: "blur",
                        },
                    ],
                    supUnitNature: [
                        {
                            required: true,
                            message: "请选择单位性质",
                            trigger: "change",
                        },
                    ],
                    siteName: [
                        {
                            required: true,
                            message: "请输入站点名称",
                            trigger: "blur",
                        },
                    ],
                    supportUnit: [
                        {
                            required: true,
                            message: "请输入依托单位",
                            trigger: "blur",
                        },
                    ],
                    areaId: [
                        {
                            required: true,
                            message: "请至少选择一个省",
                            trigger: "change",
                        },
                    ],
                    cityId: [
                        {
                            required: true,
                            message: "请至少选择一个城市",
                            trigger: "change",
                        },
                    ],
                    districtId: [
                        {
                            required: true,
                            message: "请至少选择一个区/县",
                            trigger: "change",
                        },
                    ],
                },
                accountTypeOptions: [
                    // {
                    //     label: "函授站（学校）",
                    //     value: 0,
                    // },
                    {
                        label: "教师",
                        value: 1,
                    },
                    {
                        label: "学生",
                        value: 2,
                    },
                  {
                    label: "院校",
                    value: 4,
                  }
                ],
                supUnitNatureOptions: [
                    {
                        label: "民办",
                        value: 0,
                    },
                    {
                        label: "公办",
                        value: 1,
                    },
                ],
                citynameOptions1: [],
                citynameOptions2: [],
                citynameOptions3: [],
                imageUrl: ''
            };
        },
        created() {
            this.getList();
        },
        methods: {
            searchUser() {
                let that = this;
                if (that.userListSearch.loginAccount) {
                    that.userListParm.loginAccount = that.userListSearch.loginAccount;
                } else {
                    that.userListParm.loginAccount = null;
                }
                if (that.userListSearch.phone) {
                    that.userListParm.phone = that.userListSearch.phone;
                } else {
                    that.userListParm.phone = null;
                }
                if (that.userListSearch.accountType != null) {
                    that.userListParm.accountType = that.userListSearch.accountType;
                } else {
                    that.userListParm.accountType = null;
                }
                this.getList();
            },
            // 初始化数据
            getList() {
                this.getPermission('sysUser:list', () => {
                    let that = this;
                    this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                    let userListParm = that.userListParm;
                  this.openLoadingView()
                    this.$axios_supermall({
                        url: "hnjxjy-admin/sysLogin/queryAllSysUser",
                        method: "GET",
                        headers: this.loginList,
                        params: userListParm,
                    }).then((res) => {
                        if (res.data.code !== 2000) {
                            // that.$message({
                            //     message: res.data.message,
                            //     type: "warning",
                            // });
                            this.loadingView.close()
                            return;
                        }
                        this.userList = res.data.data.list;
                        that.totals = res.data.data.total;
                        this.loadingView.close()
                    }).catch((err) => {
                      this.loadingView.close()
                    });
                });
            },

            // 查询角色列表
            querySysRole() {
                this.$axios({
                    url: "hnjxjy-admin/sysRole/querySysRole",
                    method: "GET",
                    headers: this.loginList,
                    params: {
                        name: this.search.name,
                        page: 1,
                        pageSize: 100,
                    },
                }).then((res) => {
                });
            },
            // 分页器
            sysPageChangeSize(newPage) {
                this.userListParm.pageSize = newPage;
                this.getList();
            },
            //页数
            sysCurrentChange(page) {
                this.userListParm.page = page;
                this.getList();
            },
            // 添加用户
            addUser() {
                this.queryAllEduArea();
                this.userFormDialogVisible = true;
            },
            //  绑定角色
            async bindRole(row) {
              let that = this;
              this.binduser = row;
              this.openLoadingView();
              await this.$axios({
                url: "hnjxjy-admin/sysRole/querySysRole",
                method: "GET",
                headers: this.loginList,
                params: {},
              }).then((res) => {
                if (res.data.code !== 2000) {
                  // that.$message({
                  //     message: res.data.message,
                  //     type: "warning",
                  // });
                  this.loadingView.close();
                  return;
                }
                this.loadingView.close();
                this.roleList = res.data.data.list;
                this.bindRuleDialogVisible = true;
              }).catch((err) => {
                this.loadingView.close();
              })
            },
            // 选择绑定事件
            async doBindRole(index, row) {
                let that = this;
                let data = new Object();
                data.userId = this.binduser.id;
                data.roleId = row.id;
                if (data.userId !== 0) {
                  this.openLoadingView();
                    await this.$axios({
                        url: "hnjxjy-admin/sysLogin/addRoleByUser",
                        data: data,
                        method: "POST",
                        headers: this.loginList,
                    }).then((res) => {
                        if (res.data.code !== 2000) {
                            // that.$message({
                            //     message: res.data.message,
                            //     type: "warning",
                            // });
                          this.loadingView.close();
                          this.bindRuleDialogVisible = false;
                          return;
                        }
                        that.$message({
                            message: res.data.message,
                            type: "success",
                        });
                      this.loadingView.close();
                      this.bindRuleDialogVisible = false;
                    });
                }
            },
            queryAllEduArea() {
                let that = this;
                this.$axios({
                    url: "hnjxjy-core/eduArea/queryAllEduArea",
                    method: "GET",
                    headers: this.loginList,
                    params: {},
                }).then((res) => {
                    if (res.data.code !== 2000) {
                        // that.$message({
                        //     message: res.data.message,
                        //     type: "warning",
                        // });
                        return;
                    } else {
                        that.citynameOptions1 = res.data.data;
                    }
                });
            },
            queryCityByAreaId(id) {
                let that = this;
                this.$axios({
                    url: "hnjxjy-core/eduArea/queryCityByAreaId?id=" + id,
                    method: "GET",
                    headers: this.loginList,
                    params: {},
                }).then((res) => {
                    if (res.data.code !== 2000) {
                        // that.$message({
                        //     message: res.data.message,
                        //     type: "warning",
                        // });
                        return;
                    } else {
                        that.citynameOptions2 = res.data.data;
                    }
                });
            },
            queryCityByAreaId3(id) {
                let that = this;
                this.$axios({
                    url: "hnjxjy-core/eduArea/queryCityByAreaId?id=" + id,
                    method: "GET",
                    headers: this.loginList,
                    params: {},
                }).then((res) => {
                    if (res.data.code !== 2000) {
                        // that.$message({
                        //     message: res.data.message,
                        //     type: "warning",
                        // });
                        return;
                    } else {
                        that.citynameOptions3 = res.data.data;
                    }
                });
            },
            cityHandleChange1(e) {
                this.queryCityByAreaId(e);
            },
            cityHandleChange2(e) {
                this.queryCityByAreaId3(e);
            },
            cityHandleChange3(e) {
            },
            fileuploadChange(file, fileList) {
                this.userFormData.file = file.raw;
                this.imageUrl = URL.createObjectURL(file.raw);
            },
            userFormSubmit() {
                let that = this;
                this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                let opturl = "addSysLoginUser"; //添加用户
                var param = new FormData(); // FormData 对象
                param.append("file", this.userFormData.file); // 文件对象
                param.append("loginAccount", this.userFormData.loginAccount);
                param.append("loginPassword", this.userFormData.loginPassword);
                param.append("accountType", this.userFormData.accountType);
                param.append("siteName", this.userFormData.siteName);
                param.append("supportUnit", this.userFormData.supportUnit);
                param.append("supUnitNature", this.userFormData.supUnitNature);
                param.append("areaId", this.userFormData.areaId);
                param.append("cityId", this.userFormData.cityId);
                param.append("districtId", this.userFormData.districtId);
                param.append("phone", this.userFormData.phone);
                if (this.userFormData.id > 0) {
                    param.append("id", this.userFormData.id); // 有id，修改用户
                    opturl = "addSysLoginUser";
                }
                this.openLoadingView();
                this.$axios
                    .post("hnjxjy-admin/sysLogin/" + opturl, param, {
                        headers: that.loginList,
                    })
                    .then((res) => {
                        if (res.data.code !== 2000) {
                            // that.$message({
                            //     message: res.data.message,
                            //     type: "warning",
                            // });
                            this.loadingView.close();
                            return;
                        } else {
                            that.$message({
                                message: res.data.message,
                                type: "success",
                            });
                            this.userFormDialogVisible = false;
                            this.$refs['elForm'].resetFields();
                            this.imageUrl = null;
                            that.getList();
                        }
                    }).catch((err) => {
                  this.$message.error("添加失败");
                  this.loadingView.close();
                })
            },
          userFormDialogVisibleFalse(){
            this.userFormDialogVisible = false;
            this.imageUrl = null;
            this.$refs['elForm'].resetFields();
          },
          beforeClose(done){
            done();
            this.imageUrl = null;
            this.$refs['elForm'].resetFields();
          },
        },
    };
</script>
<style scoped>
    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding: 1rem;
    }

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    .rigth {
        width: 93%;
        display: inline-block;
        height: 870px;
        overflow-y: scroll;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    ::v-deep .el-form-item__content {
        text-align: left;
    }

    @media (max-width: 750px) {
        .rigth {
            width: 100%;
        }

        .search {
            padding: 0;
        }

        .table-wrap {
            overflow: auto;
            padding: 0.4rem 0rem;
        }

        .pagin {
            overflow-x: scroll;
        }

        .pagin::-webkit-scrollbar {
            width: 0;
            height: 0;
        }

        .search >>> .el-input {
            width: 92%;
            margin: 0.3rem 0;
        }
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
