<template>
  <div>


    <div>
      <!-- 标题 -->
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        学生账号
      </div>
      <!-- input框 -->
      <div class="search">
        <el-row style="padding: 8px 8px 8px 8px">
          <el-button type="primary" icon="el-icon-circle-check" v-permission="'sysEduStuInfo:accYEnable'" @click="stateChange(0)"
            >启用账号</el-button
          >
          <el-button type="warning" icon="el-icon-circle-close" v-permission="'sysEduStuInfo:accYEnable'" @click="stateChange(1)"
            >禁用账号</el-button
          >
          <el-button type="danger" icon="el-icon-warning-outline" v-permission="'sysEduStuInfo:reset'" @click="resetPassword"
            >重置密码</el-button
          >
           <el-button type="success" icon="el-icon-download" @click="excelStudentInfo">导出</el-button>
<!--          <el-link style="margin-left:20px;border-radius: 4px;background-color: #409eff;padding: 10px;color: #fff;" icon="el-icon-download" v-permission="'sysEduStuInfo：import'" href="http://d099d5b3a9ef.ngrok.io/hnjxjy-admin/sysEduStuInfo/excelStudentInfo">导出</el-link>-->
        </el-row>
        <el-form class="demo-ruleForm" inline>
          <el-form-item style="margin-bottom: 0; margin-left: 7px">
            <el-input
              placeholder="姓名/学号查询"
              prefix-icon="el-icon-user"
              v-model="userListSearch.name"
            >
            </el-input>
          </el-form-item>
          <el-form-item style="margin-bottom: 0">
            <el-select
              v-model="userListSearch.siteId"
              placeholder="请选择函授站"
              clearable
              :style="{ width: '100%' }"
            >
              <el-option
                v-for="(item, index) in siteList"
                :key="index"
                :label="item.siteName"
                :value="item.id"
                :disabled="item.disabled"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-bottom: 0">
            <el-button type="primary" icon="el-icon-search" @click="searchUser"
              >搜索</el-button
            >
            <!-- <el-button icon="el-icon-edit" type="primary" @click="addUser"
              >添加</el-button> -->
          </el-form-item>
        </el-form>
      </div>
      <!-- 表格 -->
      <div class="table-wrap">
        <el-table :data="userList" border ref="multipleTable"
                  size="medium"
                  height="585px"
                  max-height="585px"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="学号" prop="studentNo"> </el-table-column>
          <el-table-column label="账号ID" prop="loginId"> </el-table-column>
          <el-table-column label="账号" prop="loginAccount"> </el-table-column>
          <el-table-column label="姓名" prop="name"> </el-table-column>
          <el-table-column label="性别" prop="sex">
            <template slot-scope="scope">
              <span v-if="scope.row.sex == 1">女</span>
              <span v-if="scope.row.sex == 0">男</span>
            </template>
          </el-table-column>
          <el-table-column label="身份证号" prop="certificateNo">
          </el-table-column>
          <el-table-column label="联系电话" prop="contactPhone">
          </el-table-column>
          <el-table-column label="电子邮件" prop="email"> </el-table-column>
          <el-table-column label="状态" prop="accountStatus">
            <template slot-scope="scope">
              <span v-if="scope.row.accountStatus == 1"><el-button type="warning" plain disabled>锁定</el-button></span>
              <span v-if="scope.row.accountStatus == 0"><el-button type="primary" plain disabled>正常</el-button></span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagin" style="padding: 1rem">
          <el-pagination
            @size-change="sysPageChangeSize"
            @current-change="sysCurrentChange"
            :current-page="userListParm.page"
            :page-sizes="[10, 30, 50, 100]"
            :page-size="userListParm.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="userListParm.totals"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 绑定角色 -->
    <el-dialog
      title="绑定角色"
      :visible.sync="bindRuleDialogVisible"
      width="40%"
    >
      <div>
        <el-table
          :data="roleList"
          border
          style="width: 100%"
          highlight-current-row
          size="medium"
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
        >
          <el-table-column prop="name" label="角色名称" width="250">
          </el-table-column>
          <el-table-column prop="name" label="角色描述"> </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                plain
                @click="doBindRole(scope.$index, scope.row)"
                >确认绑定</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 1rem"></div>
      </div>
    </el-dialog>
    <div>
      <el-dialog
        title="添加用户"
        :visible.sync="userFormDialogVisible"
        style="padding: 30px"
      >
        <el-form
          ref="elForm"
          :model="userFormData"
          :rules="userFormRules"
          size="medium"
          label-width="100px"
          label-position="left"
        >
          <el-col :span="12" style="padding: 10px">
            <el-form-item label="登录账号" prop="loginAccount">
              <el-input
                v-model="userFormData.loginAccount"
                placeholder="请输入登录账号"
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="padding: 10px">
            <el-form-item label="账号类型" prop="accountType">
              <el-select
                v-model="userFormData.accountType"
                disabled
                placeholder="请选择账号类型"
                clearable
                :style="{ width: '100%' }"
              >
                <el-option
                  v-for="(item, index) in accountTypeOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="padding: 10px">
            <el-form-item label="密码" prop="loginPassword">
              <el-input
                v-model="userFormData.loginPassword"
                placeholder="请输入密码"
                clearable
                show-password
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="padding: 10px">
            <el-form-item label="站点名称" prop="siteName">
              <el-input
                v-model="userFormData.siteName"
                placeholder="请输入站点名称"
                clearable
                :style="{ width: '100%' }"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="padding: 10px">
            <el-form-item label="依托单位" prop="supportUnit">
              <el-input
                v-model="userFormData.supportUnit"
                placeholder="请输入依托单位"
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="padding: 10px">
            <el-form-item label="单位性质" prop="supUnitNature">
              <el-select
                v-model="userFormData.supUnitNature"
                placeholder="请选择单位性质"
                clearable
                :style="{ width: '100%' }"
              >
                <el-option
                  v-for="(item, index) in supUnitNatureOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="padding: 10px">
            <el-form-item label="省" prop="areaId">
              <el-select
                v-model="userFormData.areaId"
                placeholder="请选择城市区域"
                @change="cityHandleChange1"
              >
                <el-option
                  v-for="item in citynameOptions1"
                  :key="item.id"
                  :label="item.areaName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="padding: 10px">
            <el-form-item label="市" prop="cityId">
              <el-select
                v-model="userFormData.cityId"
                placeholder="请选择城市区域"
                @change="cityHandleChange2"
              >
                <el-option
                  v-for="item in citynameOptions2"
                  :key="item.id"
                  :label="item.areaName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="padding: 10px">
            <el-form-item label="区/县" prop="districtId">
              <el-select
                v-model="userFormData.districtId"
                placeholder="请选择城市区域"
                @change="cityHandleChange3"
              >
                <el-option
                  v-for="item in citynameOptions3"
                  :key="item.id"
                  :label="item.areaName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="padding: 10px">
            <el-form-item label="院校logo" prop="file" required>
              <el-upload
                ref="upload"
                action=""
                :on-change="fileuploadChange"
                :auto-upload="false"
                :file-list="fileList"
                list-type="picture"
              >
                <el-button size="small" type="primary" icon="el-icon-upload"
                  >点击上传</el-button
                >
              </el-upload>
            </el-form-item>
          </el-col>
        </el-form>
        <div slot="footer">
          <el-button>取消</el-button>
          <el-button type="primary" @click="userFormSubmit">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>


export default {

  data() {
    return {
      // strage
      loginList: {},
      fileList: [],
      // 初始化数据
      siteList: [],
      userList: [],
      title: "系统用户",
      binduser: {},
      // 分页器
      userListSearch: {
        siteId: "",
        name: "",
      },
      userListParm: {
        page: 1,
        pageSize: 10,
        totals: 0,
        siteId: "",
        name: "",
      },
      //  绑定角色对话框
      bindRuleDialogVisible: false,
      // 绑定角色table
      roleList: [],
      userFormDialogVisible: false,
      userFormData: {
        loginAccount: "",
        accountType: 0,
        loginPassword: "",
        supUnitNature: "",
        siteName: "",
        supportUnit: "",
        areaId: "",
        cityId: "",
        districtId: "",
        file: null,
      },
      userFormRules: {
        loginAccount: [
          {
            required: true,
            message: "请输入登录账号",
            trigger: "blur",
          },
        ],
        accountType: [
          {
            required: true,
            message: "请选择账号类型",
            trigger: "change",
          },
        ],
        loginPassword: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        supUnitNature: [
          {
            required: true,
            message: "请选择单位性质",
            trigger: "change",
          },
        ],
        siteName: [
          {
            required: true,
            message: "请输入站点名称",
            trigger: "blur",
          },
        ],
        supportUnit: [
          {
            required: true,
            message: "请输入依托单位",
            trigger: "blur",
          },
        ],
        areaId: [
          {
            required: true,
            message: "请至少选择一个省",
            trigger: "change",
          },
        ],
        cityId: [
          {
            required: true,
            message: "请至少选择一个城市",
            trigger: "change",
          },
        ],
        districtId: [
          {
            required: true,
            message: "请至少选择一个区/县",
            trigger: "change",
          },
        ],
      },
      accountTypeOptions: [
        {
          label: "函授站（学校）",
          value: 0,
        },
        {
          label: "教师",
          value: 1,
        },
        {
          label: "学生",
          value: 2,
        },
      ],
      supUnitNatureOptions: [
        {
          label: "民办",
          value: 0,
        },
        {
          label: "公办",
          value: 1,
        },
      ],
      citynameOptions1: [],
      citynameOptions2: [],
      citynameOptions3: [],
    };
  },
  created() {
    this.getList();
    this.queryAllSite();
  },
  methods: {
    searchUser() {
      let that = this;
      if (that.userListSearch.name) {
        that.userListParm.name = that.userListSearch.name;
      } else {
        that.userListParm.name = null;
      }
      if (that.userListSearch.siteId) {
        that.userListParm.siteId = that.userListSearch.siteId;
      } else {
        that.userListParm.siteId = null;
      }
      this.getList();
    },
    // 初始化数据
    getList() {
      this.getPermission('sysEduStuInfo:queryAllStuInfo', () => {
      this.openLoadingView()
      let that = this;
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      let userListParm = that.userListParm;
      this.$axios({
        url: "hnjxjy-admin/sysEduStuInfo/queryAllStuInfo",
        method: "GET",
        headers: this.loginList,
        params: userListParm,
      }).then((res) => {
        if (res.data.code !== 2000) {
          // that.$message({
          //   message: res.data.message,
          //   type: "warning",
          // });
          return;
        }
        this.userList = res.data.data.list;
        that.userListParm.totals = res.data.data.total;

        this.loadingView.close()
      });
      });
    },

    // 查询角色列表
    querySysRole() {
      this.$axios({
        url: "hnjxjy-admin/sysRole/querySysRole",
        method: "GET",
        headers: this.loginList,
        params: {
          name: this.search.name,
          page: 1,
          pageSize: 100,
        },
      }).then((res) => {
      });
    },
    // 分页器
    sysPageChangeSize(newPage) {
      this.userListParm.pageSize = newPage;
      this.getList();
    },
    //页数
    sysCurrentChange(page) {
      this.userListParm.page = page;
      this.getList();
    },
    // 添加用户
    addUser() {
      this.queryAllEduArea();
      this.userFormDialogVisible = true;
    },
    //  绑定角色
    async bindRole(row) {
      let that = this;
      this.binduser = row;
      await this.$axios({
        url: "hnjxjy-admin/sysRole/querySysRole",
        method: "GET",
        headers: this.loginList,
        params: {},
      }).then((res) => {
        if (res.data.code !== 2000) {
          // that.$message({
          //   message: res.data.message,
          //   type: "warning",
          // });
          return;
        }

        this.roleList = res.data.data.list;
      });
      this.bindRuleDialogVisible = true;
    },
    // 选择绑定事件
    async doBindRole(index, row) {
      let that = this;
      let data = new Object();
      data.userId = this.binduser.id;
      data.roleId = row.id;
      if (data.userId !== 0) {
        await this.$axios({
          url: "hnjxjy-admin/sysLogin/addRoleByUser",
          data: data,
          method: "POST",
          headers: this.loginList,
        }).then((res) => {
          if (res.data.code !== 2000) {
            // that.$message({
            //   message: res.data.message,
            //   type: "warning",
            // });
            return;
          }
          that.$message({
            message: res.data.message,
            type: "success",
          });
        });
      }
    },
    queryAllEduArea() {
      let that = this;
      this.$axios({
        url: "hnjxjy-core/eduArea/queryAllEduArea",
        method: "GET",
        headers: this.loginList,
        params: {},
      }).then((res) => {
        if (res.data.code !== 2000) {
          // that.$message({
          //   message: res.data.message,
          //   type: "warning",
          // });
          return;
        } else {
          that.citynameOptions1 = res.data.data;
        }
      });
    },
    queryCityByAreaId(id) {
      let that = this;
      this.$axios({
        url: "hnjxjy-core/eduArea/queryCityByAreaId?id=" + id,
        method: "GET",
        headers: this.loginList,
        params: {},
      }).then((res) => {
        if (res.data.code !== 2000) {
          // that.$message({
          //   message: res.data.message,
          //   type: "warning",
          // });
          return;
        } else {
          that.citynameOptions2 = res.data.data;
        }
      });
    },
    queryCityByAreaId3(id) {
      let that = this;
      this.$axios({
        url: "hnjxjy-core/eduArea/queryCityByAreaId?id=" + id,
        method: "GET",
        headers: this.loginList,
        params: {},
      }).then((res) => {
        if (res.data.code !== 2000) {
          // that.$message({
          //   message: res.data.message,
          //   type: "warning",
          // });
          return;
        } else {
          that.citynameOptions3 = res.data.data;
        }
      });
    },
    cityHandleChange1(e) {
      this.queryCityByAreaId(e);
    },
    cityHandleChange2(e) {
      this.queryCityByAreaId3(e);
    },
    cityHandleChange3(e) {
    },
    fileuploadChange(file, fileList) {
      this.userFormData.file = file.raw;
    },
    userFormSubmit() {
      let that = this;
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      let opturl = "addSysLoginUser"; //添加用户
      var param = new FormData(); // FormData 对象
      param.append("file", this.userFormData.file); // 文件对象
      param.append("loginAccount", this.userFormData.loginAccount);
      param.append("loginPassword", this.userFormData.loginPassword);
      param.append("accountType", this.userFormData.accountType);
      param.append("siteName", this.userFormData.siteName);
      param.append("supportUnit", this.userFormData.supportUnit);
      param.append("supUnitNature", this.userFormData.supUnitNature);
      param.append("areaId", this.userFormData.areaId);
      param.append("cityId", this.userFormData.cityId);
      param.append("districtId", this.userFormData.districtId);
      if (this.userFormData.id > 0) {
        param.append("id", this.userFormData.id); // 有id，修改用户
        opturl = "addSysLoginUser";
      }
      this.$axios
        .post("hnjxjy-admin/sysLogin/" + opturl, param, {
          headers: that.loginList,
        })
        .then((res) => {
          if (res.data.code !== 2000) {
            // that.$message({
            //   message: res.data.message,
            //   type: "warning",
            // });
            return;
          } else {
            that.$message({
              message: res.data.message,
              type: "success",
            });
            this.userFormDialogVisible = false;
            that.getList();
          }
        });
    },
    queryAllSite() {
      let that = this;
      let formData = new FormData();
      formData.append("isAll","1");
      this.$axios_supermallData.post('hnjxjy-core/eduSite/queryAllSite',formData).then((res) => {
        if (res.data.code !== 2000) {
          // that.$message({
          //   message: res.data.message,
          //   type: "warning",
          // });
          return;
        } else {
          that.siteList = res.data.data;
        }
      });
    },
    stateChange(status){
      let that=this;
      const _selectData = this.$refs.multipleTable.selection;
      let data=new Object();
      let loginIds="";
      data.status=status;
      _selectData.forEach(item=>{
        loginIds=loginIds+item.loginId+",";
      })
      loginIds=loginIds.substr(0,loginIds.length-1);
      data.loginIds=loginIds;
      this.$axios({
          url: "hnjxjy-admin/sysEduStuInfo/updateAccountStatus",
          params: data,
          method: "POST",
          headers: this.loginList,
        }).then((res) => {
          if (res.data.code !== 2000) {
            // that.$message({
            //   message: res.data.message,
            //   type: "warning",
            // });
            return;
          }
          that.$message({
            message: res.data.message,
            type: "success",
          });
          this.getList();
        });
    },
    resetPassword(){
      let that=this;
      const _selectData = this.$refs.multipleTable.selection;
      let data=new Object();
      let loginIds="";
      _selectData.forEach(item=>{
        loginIds=loginIds+item.loginId+",";
      })
      loginIds=loginIds.substr(0,loginIds.length-1);
      data.loginIds=loginIds;
      this.$axios({
          url: "hnjxjy-admin/sysEduStuInfo/resetPassword",
          params: data,
          method: "POST",
          headers: this.loginList,
        }).then((res) => {
          if (res.data.code !== 2000) {
            // that.$message({
            //   message: res.data.message,
            //   type: "warning",
            // });
            return;
          }
          that.$message({
            message: res.data.message,
            type: "success",
          });
          this.getList();
        });
    },
    excelStudentInfo(){
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-admin/sysEduStuInfo/excelStudentInfo",
        responseType: "blob",
        headers: objPar,
      }).then((res) => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        let url = window.URL.createObjectURL(blob);
        window.location.href = url;
        this.loadingView.close()
        this.$message.success("操作成功")
      }).catch((err) => {
        this.$message.error("操作失败");
        this.loadingView.close();
      });
    }
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  display: inline-block;
  height: 870px;
  overflow-y: scroll;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
@media (max-width: 750px) {
  .rigth {
    width: 100%;
  }
  .search {
    padding: 0;
  }
  .table-wrap {
    overflow: auto;
    padding: 0.4rem 0rem;
  }
  .pagin {
    overflow-x: scroll;
  }
  .pagin::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .search >>> .el-input {
    width: 92%;
    margin: 0.3rem 0;
  }
}
</style>
