<template>
  <div>


    <div>
      <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
        角色管理
      </div>
      <div class="search">
        <el-form class="demo-ruleForm" inline>
          <el-form-item style="margin-bottom: 0">
            <el-input placeholder="请输入内容" prefix-icon="el-icon-search" v-model="keyword">
            </el-input>
          </el-form-item>
          <el-form-item style="margin-bottom: 0">
            <el-button type="primary" icon="el-icon-search" @click="querySysRole">搜索</el-button>
            
            <el-button icon="el-icon-edit" type="primary" @click="addGrade" v-permission="'sysRole:insert'">添加</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table ref="multipleTable" :data="roleList" border size="medium" height="630" max-height="630"
          :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">
          <el-table-column label="序号" width="50px" type="index"> </el-table-column>
          <el-table-column label="角色名" prop="name"> </el-table-column>
          <el-table-column label="角色编码" prop="code"> </el-table-column>
          <el-table-column label="创建时间" prop="createTime">
          </el-table-column>
          <el-table-column label="操作" width="180" fixed="right">
            <template slot-scope="scope">
              <el-button v-permission="'sysRole:update'" @click="editor(scope.row)" type="primary"
                size="small">编辑</el-button>
              <el-button v-permission="'sysRole:deleted'" @click="deletedRoleByRoleId(scope.row)" type="danger"
                size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 1rem">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="roleParm.page" :page-sizes="[10, 20, 30, 40]" :page-size="roleParm.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="roleParm.total">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 创建/编辑 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
      <el-form :model="roleFormData" ref="ruleForm" class="demo-ruleForm" label-width="120px" :rules="rules">
        <el-form-item label="角色名：" prop="name">
          <el-input v-model="roleFormData.name" placeholder="请输入角色名" class="el-form-input-width"
            @input="() => (roleFormData.name = roleFormData.name.trim())"></el-input>
        </el-form-item>
        <el-form-item label="编码" prop="code">
          <el-input v-model="roleFormData.code" placeholder="请输入角色编码" class="el-form-input-width"
            @input="() => (roleFormData.code = roleFormData.code.trim())"></el-input>
        </el-form-item>
        <el-form-item label="角色级别：" prop="roleType">
          <el-select v-model="roleFormData.roleType" class="el-form-input-width">
            <el-option v-for="item in roleTypeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="描述" prop="info">
          <el-input type="textarea" v-model="roleFormData.info"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRoleByLoginId('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>


export default {

  data() {
    return {
      keyword: "",
      title: "",
      dialogVisible: false,
      roleParm: { name: "", page: 1, pageSize: 10, total: 1 },
      roleList: [],
      roleFormData: {
        id: "",
        name: "",
        code: "",
        roleType: 0,
        info: "",
      },
      roleTypeList: [
        {
          value: 0,
          label: "函授站（学校）",
        },
        {
          value: 1,
          label: "教师",
        },
        {
          value: 2,
          label: "学生",
        },
      ],
      rules: {
        name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入编码', trigger: 'change' },
          { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
        ],

        roleType: [
          { type: 'date', required: true, message: '请选择角色级别', trigger: 'change' }
        ],
        info: [
          { required: true, message: '请输入描述', trigger: 'change' }
        ],

      },
      ruleForm: ""

    };
  },
  created() {
    this.querySysRole();
  },
  methods: {
    inputChange(e) {
    },
    handleSearch() { },
    // 添加按钮
    addGrade() {
      this.title = "创建";
      this.roleFormData = {}
      this.dialogVisible = true;
    },
    // 点击修改
    editor(row) {
      this.title = "修改";
      this.dialogVisible = true;
      this.roleFormData = {
        id: 0,
        name: "",
        code: "",
        roleType: 1,
        info: "",
      };
      this.roleFormData = JSON.parse(JSON.stringify(row));
    },

    handleSizeChange(val) {
      this.roleParm.pageSize = val;
      this.querySysRole();
    },
    handleCurrentChange(val) {
      this.roleParm.page = val;
      this.querySysRole();
    },
    querySysRole() {
      this.getPermission('sysRole:list', () => {
        let that = this;
        that.roleParm.name = that.keyword;
        this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
        this.openLoadingView();
        this.$axios({
          url: "hnjxjy-admin/sysRole/querySysRole",
          headers: this.loginList,
          params: that.roleParm
        }).then((res) => {
          if (res.data.code !== 2000) {
            // that.$message({
            //   message: res.data.message,
            //   type: "warning",
            // });
            this.loadingView.close();
            return;
          }
          this.loadingView.close();
          that.roleParm.total = res.data.data.total;
          that.roleList = res.data.data.list;
        });
      });
    },
    addRoleByLoginId(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          let that = this;
          this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
          let opturl = "addRoleByLoginId"; //添加角色
          if (this.roleFormData.id > 0) {
            opturl = "modifyRoleByRoleId";
          }
          this.$axios.post("hnjxjy-admin/sysRole/" + opturl, that.roleFormData, {
            headers: that.loginList,
          }).then((res) => {
            if (res.data.code !== 2000) {
              return;
            } else {
              that.$message({
                message: res.data.message,
                type: "warning",
              });
              this.dialogVisible = false;
              that.querySysRole();
            }
          });
        }
      });

    },
    deletedRoleByRoleId(row) {
      let that = this;
      this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
      let param = new Object();
      param.id = row.id;
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.$axios
            .post(
              "hnjxjy-admin/sysRole/deletedRoleByRoleId",
              param,
              {
                headers: that.loginList,
              }
            )
            .then((res) => {
              if (res.data.code !== 2000) {
                // that.$message({
                //   message: res.data.message,
                //   type: "warning",
                // });
                return;
              }
              that.$message({
                message: res.data.message,
                type: "warning",
              });
              that.querySysRole();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    }
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}

.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}

.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}

.el-form-input-width {
  width: 100%;
}

.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}

.left {
  width: 7%;
  display: inline-block;
}
</style>
