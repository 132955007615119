<template>
 <div id="contain">



    <div class="right">
      <div
          style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        学习必备
      </div>

       <div class="top_btn">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="dialogVisible = true">添加</el-button>
        <el-button type="primary" icon="el-icon-delete">删除</el-button>
       </div>
         <div class="search">
      <span class="yingyong">应用</span>
      <el-select v-model="value" placeholder="请选择" style="width: 300px;">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
     <div class="main_contain">
       <span id="aaaaa">共选中 10 条数据</span>
       <el-table
        :data="tableData"
        border
        height="500"
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        style="width: 100%">
         <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          label="操作"
          width="180">
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="deleteRow(scope.$index, tableData)"
              type="text"
              size="small">
              内容
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          sortable
          label="标题">
        </el-table-column>
        <el-table-column
          prop="time"
          sortable
          label="发布时间">
        </el-table-column>
        <el-table-column
          prop="issuer"
          sortable
          label="发布人">
        </el-table-column>
         <el-table-column
          prop="yingyong"
          sortable
          label="置顶">
        </el-table-column>
        <el-table-column
          prop="yingyong"
          sortable
          label="应用">
        </el-table-column>
      </el-table>
    </div>
 <el-dialog
        class="add"
      title="新增"
      :visible.sync="dialogVisible"
      width="70%"
      center
      >
      <el-button type="primary" icon="el-icon-circle-close" @click="dialogVisible = false">关闭</el-button>
      <el-button type="primary" icon="el-icon-view">预览</el-button>
      <el-button type="primary" icon="el-icon-folder-add" @click="dialogVisible = false">保存后关闭</el-button>

    </el-dialog>
    </div>
 </div>
</template>

<script>


export default {



    data() {
      return {
        options: [],
        options2: [],
        tableData: [],
        dialogVisible: false,
        value:'',
        form:'',
        checked:'',
        value1:'',
        input:'',
        content:''
      }
    },
    mounted () {
      this.init();
    },
    methods:{
      init(){
        this.getSelect();
        this.getFabuDuxiang();
      },
      getSelect(){
        this.options = [{
          value: '1',
          label: '系统设置'
        }, {
          value: '2',
          label: '基础数据模块'
        }, {
          value: '3',
          label: '学校所拥有'
        }]
      },
      getFabuDuxiang(){
        this.options2 = [{
          value: '1',
          label: '总校教师'
        },{
          value: '2',
          label: '总校管理员'
        },{
          value: '3',
          label: '总校教师管理员'
        },{
          value: '4',
          label: '学生'
        }];
      },
    }
}
</script>
<style scoped>
.left {
  width: 7%;
  display: inline-block;
}
/*.yingyong{*/
/*  margin-right: 10px;*/
/*  text-align: left;*/
/*}*/
.right {
  float: right;
  width: 93%;
  display: inline-block;
  height: 870px;
  overflow-y: scroll;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);

 text-align: left;
}
.left {
  width: 7%;
  display: inline-block;
}
.add{
    text-align: left im !important;
}
.yingyong{
  margin-right: 10px;
  font-weight: bold;
  color: #555;
}
#aaaaa{
  color: lightskyblue;
}
.top_btn{
  margin: 10px 10px 10px 10px;
   text-align: left;
}
.search{
  margin: 20px 10px 30px 10px;
  text-align: left;
}
.main_contain{
   margin: 20px 10px 30px 10px;
   text-align: left;
}
</style>
