<template>
  <div>


    <div>
      <div
        style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left"
      >
        课程库
      </div>
      <div class="search">
        <el-form class="demo-ruleForm">
          <el-form-item style="margin-bottom:0">
            <el-button icon="el-icon-edit" type="primary" @click="add()"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="table-wrap">
        <div class="warping">
            <div style="width:50%;display:inline-block">
                报税开始时间：
                <el-date-picker
                    v-model="value1"
                    type="datetime"
                    placeholder="选择日期时间"
                >
                </el-date-picker>
            </div>
            <div style="width:50%;display:inline-block">
                报税结束时间：
                <el-date-picker
                    v-model="value1"
                    type="datetime"
                    placeholder="选择日期时间"
                >
                </el-date-picker>
            </div>
             <div style="margin-top:2rem">
                学生报税文件内容：
               <el-input
               style="width:90%"
                type="textarea"
                :rows="4"
                placeholder="请输入内容"
                v-model="textarea">
                </el-input>
            </div>
             <div style="margin-top:2rem">
                填表说明：
               <el-input
               style="width:90%"
                type="textarea"
                :rows="4"
                placeholder="请输入内容"
                v-model="textarea">
                </el-input>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


export default {

  data() {
    return {
      textarea: "",
      search: {
        name: "",
      },
      teacherForm: {
        name: "",
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
    };
  },
  methods: {
    add(){

    },
    handleSearch() {},
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    },
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  padding: 1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem;
}
.warping {
  background-color: #fff;
  padding: 1rem;
  font-size: 14px;
  text-align: left;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth {
  width: 93%;
  display: inline-block;
  height: 870px;
  overflow-y: scroll;
  /* background-color: #fff; */
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
.portrait_Sprite {
  padding: 0.2rem 1rem;
  display: inline-block;
  background-color: skyblue;
  color: #fff;
  border-radius: 10%;
  font-size: 13px;
}
</style>