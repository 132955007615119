<template>
  <div>

    <div class="header">

      <div style="width:98%;">
        <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">课程督学助学统计</div>
        <div class="search">
          <el-form :model="search" class="demo-ruleForm" inline>
            <div class="search_content" style="margin-top: 10px">
              <el-form-item>
                <el-button icon="el-icon-download" type="primary" v-permission="'eduCourseTeacherStat:exportCourseStatList'" @click="download">导出</el-button>
                <el-button icon="el-icon-search" type="primary" @click="handleSearch">查询</el-button>
              </el-form-item>
            </div>
            <div class="arrow">
              <el-form-item label="课程名称/课程编号">
              <el-input
                  placeholder="课程名称/课程编号"
                  v-model="search.name"
                  class="input-with-select"
              >
              </el-input>
              </el-form-item>
              <div class="block">
                <el-form-item label="开始时间" style="margin-bottom: 0">
                  <el-date-picker
                      @change="clear"
                      v-model="search.startTime"
                      type="datetime"
                      placeholder="选择日期时间"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间" style="margin-bottom: 0">

                  <el-date-picker
                      @change="handleSearch"
                      v-model="search.endTime"
                      type="datetime"
                      placeholder="选择日期时间"
                  >
                  </el-date-picker>
                </el-form-item></div>
            </div>
          </el-form>
        </div>
        <div class="table-wrap">
          <el-table
              class="multipleTable"
              ref="multipleTable"
              border
              :data="tableData"
              tooltip-effect="dark"
              size="medium"
              height="600px"
              max-height="600px"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
          >
            <el-table-column type="selection" width="50"> </el-table-column>
            <el-table-column label="序号" type="index"  width="80">
            </el-table-column>
            <el-table-column prop="courseCode" show-overflow-tooltip sortable label="课程编号" >
            </el-table-column>
            <el-table-column
                prop="courseName"
                show-overflow-tooltip
                label="课程名称"
                sortable
            >
            </el-table-column>
<!--            <el-table-column-->
<!--                prop="courseCode"-->
<!--                show-overflow-tooltip-->
<!--                label="课程编号"-->
<!--                sortable-->
<!--            ></el-table-column>-->
            <el-table-column
                prop="name"
                show-overflow-tooltip
                label="教师名称"
                sortable
            ></el-table-column>
            <el-table-column
                prop="noticeNum"
                label="通知公告数目"
                sortable
            ></el-table-column>
            <el-table-column
                prop="guidancePostNum"
                label="导学资料发帖数"
                sortable
                width="150"
            ></el-table-column>
            <el-table-column
                prop="onlineNum"
                label="在线答疑提问数"
                sortable
                width="150"
            ></el-table-column>
            <el-table-column
                prop="answerNum"
                label="在线答疑回复数"
                sortable
                width="150"
            >
            </el-table-column>
            <el-table-column
                prop="noAnswerNum"
                label="在线答疑未回复数"
                sortable
                width="160"
            ></el-table-column>
            <el-table-column
                prop="onlineTo24Answer"
                label="在线24小时回复率"
                sortable
                width="160"
            ></el-table-column>
            <el-table-column
                prop="onlineTo48Answer"
                label="在线48小时回复率"
                sortable
                width="160"
            ></el-table-column>
          </el-table>
          <!-- </el-table> -->
          <div style="padding: 1rem" class="bottom">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="search.page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="search.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


export default {

  created() {
    this.handleSearch();
  },
  data() {
    return {
      total: 0,
      input3: "",
      input: "",
      dialogVisibleUpload1: false,
      dialogVisible: false,
      title: "",
      value1: "",
      value2: "",
      search: {
        name:"",
        startTime:"",
        endTime:"",
        page:1,
        pageSize:10,
      },
      teacherForm: {
        name: "",
        time: "",
      },
      tableData: [

      ],
      multipleSelection: [],


      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    };
  },
  methods: {
    // handleSelectionChange(val) {
    //   // 单选
    //   if (this.multipleSelection.length > 1) {
    //     this.multipleSelection = [];
    //   }
    //   if (val.length > 1) {
    //     this.$refs.multipleTable.clearSelection();
    //     this.$refs.multipleTable.toggleRowSelection(val.pop());
    //   }
    //   this.multipleSelection = val;
    // },
    handleSearch() {
      this.getPermission('eduCourseTeacherStat:queryCourseStatList', () => {
      this.openLoadingView()
      let fromData = new FormData();
      fromData.append("info",this.search.name);
      fromData.append("page",this.search.page);
      fromData.append("pageSize",this.search.pageSize);
      if(this.search.startTime!=null&&this.search.startTime!=""){
        let startTime = new Date(this.search.startTime).formatToLocalDateTime("yyyy-MM-dd").toString();
        fromData.append("startTime",startTime);
      }
      if(this.search.endTime!=null&&this.search.endTime!=""){
          let endTime = new Date(this.search.endTime).formatToLocalDateTime("yyyy-MM-dd").toString();
          fromData.append("endTime",endTime);
        }

      this.$axios_supermall.post("/hnjxjy-core/eduCourseTeacherStat/queryCourseStatList",fromData).then(resp=>{
        this.tableData = resp.data.data.list; //表格列表
        this.total = resp.data.data.total;
      })
      });
    },
    clear(){
      this.search.endTime=""
    },
    download() {
      let objPar = JSON.parse(localStorage.getItem("loginStrage"));
      this.openLoadingView()
      this.$axios({
        method: "get",
        url: "hnjxjy-core/eduCourseTeacherStat/exportCourseStatList",
        responseType: "blob",
        headers: objPar,
        params:{
          info:this.search.name,
          startTime:this.search.startTime,
          endTime:this.search.endTime,
        }
      })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            window.location.href = url;
            this.loadingView.close()
            this.$message.success("操作成功")
          })
          .catch((err) => {
          });
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.handleSearch();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleSearch();
    },
    handleSizeChange1(val) {
    },
    handleCurrentChange1(val) {
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>
<style scoped>
/* 通用样式 */
html {
  font-size: 16px !important;
}
/* 头部下面侧边栏css样式 */
/* 左侧 */
.header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.header_top {
  width: 7%;
}

.rigth {
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  background-color: rgba(164, 174, 224, 0.11);
  float: right;
}
.left {
  width: 7%;
  display: inline-block;
}
::v-deep .el-form-item__content {
  text-align: left;
}
/* search按钮布局 */
.search_content {
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
}
/* 表格样式 */
.arrow {
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
}
/* 输入框样式 */
.el-input {
  width: 426px;
}
.arrow >>> .el-dropdown {
  text-align: left;
}
/* 日期选择器样式 */
.container {
  width: 440px;
}
.block{
  margin-left: 30px;
}
.block>span{
  font-size: 16px;
  font-weight: 800;
  margin-right: 10px;
}
.table-wrap>span{
  color: rgb(24, 144, 255);
  float: left;
}
/* 表单样式 */
.table-wrap {
  padding: 0px 0 0 20px;
}
/* 底部分页样式 */
.bottom {
  background-color: #fff;
  text-align: left;
}
.el-pagination >>> .btn-prev {
  background-color: rgb(244, 244, 245);
  border: 1px solid rgb(244, 244, 245);
  padding-right: 3px;
  border-radius: 3px;
  color: rgb(197, 198, 205);
}
.el-pagination >>> .btn-next {
  background-color: rgb(244, 244, 245);
  border-radius: 3px;
  border: 1px solid rgb(244, 244, 245);
  padding-left: 3px;
  color: rgb(197, 198, 205);
}
/* 分页栏页面数字 */
.bottom >>> .el-pager li {
  margin: 0 5px 0 5px;
  background-color: rgb(244, 244, 245);
}
.bottom >>> .el-pager li.active {
  margin: 0 5px 0 5px;
  background-color: rgb(24, 144, 255);
  color: #fff;
}
.rigth >>> .el-table td,
.rigth >>> .el-table th {
  text-align: center;
}
</style>
