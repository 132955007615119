<template>
  <div>


    <div>
      <div style="padding:1rem;border-bottom: 1px solid #ddd;text-align:left">
        批阅教师管理
      </div>
      <div class="search">
        <el-form :model="search" class="demo-ruleForm">
          <el-form-item >
            <el-button
              icon="el-icon-download"
              type="primary"
              @click="createTeacher"
              >添加批阅教师</el-button
            >
            <el-button
              icon="el-icon-download"
              type="primary"
              @click="batchDelete(tableChecked)"
              >删除批阅教师</el-button
            >
          </el-form-item>
          <el-form-item label="姓名/工号" style="margin-bottom:0">
            <el-input
              v-model="search.name"
              clearable
              @input="() => (search.name = search.name.trim())"
              placeholder="请输入内容"
              style="width: 200px"
            ></el-input>
            <el-button icon="el-icon-search" @click="handleSearch"></el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="table-wrap">
        <el-table  border
          ref="multipleTable"
          :data="tableData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="序号" type="index">
          </el-table-column>
          <el-table-column label="姓名"  prop="name" sortable>
          </el-table-column>
          <el-table-column label="工号"  prop="num" sortable>
          </el-table-column>
          <el-table-column label="性别"  prop="sex" sortable>
          </el-table-column>
          <el-table-column label="身份证号" prop="idCard" sortable>
          </el-table-column>
          <el-table-column label="手机号"  prop="phone" sortable>
          </el-table-column>
          <el-table-column label="工作单位" prop="address" sortable>
          </el-table-column>
          <el-table-column label="备注" prop="memo">
          </el-table-column>
          <el-table-column label="操作"  fixed="right">
            <template slot-scope="scope">
              <el-button @click="editor(scope.row)" type="text" size="small"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="padding:1rem">
            <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage4"
      :page-sizes="[100, 200, 300, 400]"
      :page-size="100"
      layout="total, sizes, prev, pager, next, jumper"
      :total="400">
    </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      style="text-align: left"
    >
      <el-form
        :model="teacherForm"
        :rules="teacherRules"
        ref="ruleForm"
        class="demo-ruleForm"
        label-width="120px"
      >
        <el-form-item label="姓名：" prop="name">
          <el-input
            v-model="teacherForm.name"
            placeholder="请输入姓名"
            class="el-form-input-width"
            @input="() => (teacherForm.name = teacherForm.name.trim())"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别：" prop="sex">
          <el-select
            v-model="teacherForm.sex"
            placeholder="请选择性别"
            class="el-form-input-width"
          >
            <el-option
              v-for="item in sexList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="身份证号：" prop="idCard">
          <el-input
            v-model="teacherForm.idCard"
            placeholder="请输入身份证号"
            class="el-form-input-width"
            @input="() => (teacherForm.idCard = teacherForm.idCard.trim())"
          ></el-input>
        </el-form-item>
        <el-form-item label="工号：" prop="name">
          <el-input
            v-model="teacherForm.name"
            placeholder="请输入工号"
            class="el-form-input-width"
            @input="() => (teacherForm.name = teacherForm.name.trim())"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机：" prop="phone">
          <el-input
            v-model="teacherForm.phone"
            placeholder="请输入手机号"
            class="el-form-input-width"
            @input="() => (teacherForm.phone = teacherForm.phone.trim())"
          ></el-input>
        </el-form-item>
        <el-form-item label="工作单位：" prop="name">
          <el-input
            v-model="teacherForm.name"
            placeholder="请输入工作单位"
            class="el-form-input-width"
            @input="() => (teacherForm.name = teacherForm.name.trim())"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系地址：" prop="addres">
          <el-input
            v-model="teacherForm.addres"
            placeholder="请输入联系地址"
            class="el-form-input-width"
            @input="() => (teacherForm.addres = teacherForm.addres.trim())"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="memo">
          <el-input
            v-model="teacherForm.memo"
            placeholder="请输入备注"
            class="el-form-input-width"
            @input="() => (teacherForm.name = teacherForm.name.trim())"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSave('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>


export default {

  data() {
    return {
      total: 0,
      pageNum: 1,
      pageSize: 10,
       currentPage4: 4,
      search: {
        name: "",
      },
      teacherForm: {
        name: "",
      },
      tableChecked: [],
      tableData: [
        {
          id: "1",
          name: "王小虎",
          num: "123",
          sex: "男",
          idCard: "3201",
          phone: "13333333333",
          address: "上海市普陀区金沙江路 1518 弄",
          memo: "备注",
        },
      ],
      sexList: [
        {
          value: 0,
          label: "男",
        },
        {
          value: 1,
          label: "女",
        },
      ],
      title: "",
      dialogVisible: false,
      teacherRules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        fullName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { pattern: /^1[3456789]\d{9}$/, message: "手机号格式不正确" },
        ],
        email: [
          { required: false, message: "请输入邮箱", trigger: "blur" },
          {
            pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
            message: "邮箱格式不正确",
          },
        ],
        idCard: [
          { required: false, message: "请输入身份证号码", trigger: "blur" },
          {
            pattern: /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            message: "身份证号格式不正确",
          },
        ],
      },
    };
  },
  methods: {
    handleSearch() {},
    createTeacher() {
      this.dialogVisible = true;
      this.title = "新增批阅教师";
      this.teacherForm = {};
    },
    // 批量删除
    batchDelete(row) {
      if (this.tableChecked == "") {
        this.$message.error("请至少选择一项记录");
      } else {
        this.$confirm(`您确定要删除${row.length}数据吗?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    handleSelectionChange(val) {
      this.tableChecked = val;
    },
    editor(row) {
      this.dialogVisible = true;
      this.title = "修改批阅教师";
      this.teacherForm = row;
    },
       handleSizeChange(val) {
      },
      handleCurrentChange(val) {
      }
  },
};
</script>
<style scoped>
.search {
  text-align: left;
  margin-top: 1rem;
  /* margin-left: 1rem; */
      padding:1rem;
}
.table-wrap {
  overflow: auto;
  /* margin-left: 1rem; */
  /* margin-bottom: 1rem; */
      padding: 1rem;
}
.demo-ruleForm {
  /* width: 90%; */
  height: calc(100% - 125px);
}
.el-form-input-width {
  width: 100%;
}
.rigth{
  width: 93%;
  height: 870px;
  overflow-y: scroll;
  display: inline-block;
  background-color:#fff;
   /* background-color: rgba(164,174,224,.11); */
  float: right;
}
.left{
  width: 7%;
  display: inline-block;
}
/* 响应式 */
@media only screen and (max-width: 750px) {
 .left{
 display: 0;
 }
 .rigth{
    width: 100%;
    display: block;
 }
}
</style>