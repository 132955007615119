<template>
    <div>
        <div>
            <div style="padding: 1rem; border-bottom: 1px solid #ddd; text-align: left">
                分配权限管理
            </div>
            <div class="search">
                <el-form class="demo-ruleForm" inline>
                    <el-form-item style="margin-bottom: 0">
                        <el-input
                                placeholder="请输入内容"
                                prefix-icon="el-icon-search"
                                v-model="keyword"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item style="margin-bottom: 0">
                        <el-button
                                type="primary"
                                icon="el-icon-search"
                                @click="querySysRole"
                        >搜索
                        </el-button
                        >
                        <el-button icon="el-icon-edit" type="primary" v-permission="'syspermission:insert'"
                                   @click="addGrade"
                        >添加
                        </el-button
                        >
                    </el-form-item>
                </el-form>
            </div>
            <div class="table-wrap">
                <el-table ref="multipleTable" :data="roleList" border v-loading="loading"
                          size="medium"
                          height="630"
                          max-height="630"
                          :header-cell-style="{'text-align':'center'}"
                          :cell-style="{'text-align':'center'}"
                >
                    <el-table-column label="序号" width="50" type="index"></el-table-column>
                    <el-table-column label="角色名" prop="name"></el-table-column>
                    <el-table-column label="角色编码" prop="code"></el-table-column>
                    <el-table-column label="创建时间" prop="createTime">
                    </el-table-column>
                    <el-table-column label="操作" width="180" fixed="right">
                        <template slot-scope="scope">
                            <el-button
                                    v-permission="'syspermission:distribution'"
                                    @click="showPatchRoleDialog(scope.row)"
                                    type="primary"
                                    size="small"
                                    icon="el-icon-s-tools">
                                分配权限
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="padding: 1rem">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="roleParm.page"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="roleParm.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="roleParm.total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 创建/编辑 -->
        <el-dialog title="添加角色" :visible.sync="dialogVisible" width="30%">
            <el-form
                    :model="roleFormData"
                    ref="ruleForm"
                    class="demo-ruleForm"
                    label-width="120px">
                <el-form-item label="角色名：">
                    <el-input
                            v-model="roleFormData.name"
                            placeholder="请输入角色名"
                            class="el-form-input-width"
                            @input="() => (roleFormData.name = roleFormData.name.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="编码">
                    <el-input
                            v-model="roleFormData.code"
                            placeholder="请输入角色编码"
                            class="el-form-input-width"
                            @input="() => (roleFormData.code = roleFormData.code.trim())">
                    </el-input>
                </el-form-item>
                <el-form-item label="角色级别：">
                    <el-select
                            v-model="roleFormData.roleType"
                            class="el-form-input-width">
                        <el-option
                                v-for="item in roleTypeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="描述">
                    <el-input type="textarea" v-model="roleFormData.info"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRoleByLoginId">确 定</el-button>
      </span>
        </el-dialog>
        <!-- 分配权限 -->
        <el-dialog
                title="分配权限"
                :visible.sync="patchRoleDialogVisible"
                width="50%">
            <div class="infinite-list" style="overflow:auto;height: 400px">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <el-tag type="success" style="margin-right: 15px">菜单</el-tag>
                        <el-tag type="primary">权限</el-tag>
                    </div>
                    <el-tree
                            :data="checkedRoleMenuList"
                            node-key="id"
                            :check-strictly="true"
                            ref="menuTree"
                            :props="{ children: 'sysMenuVos', label: 'menuName' ,value:'id'}">
                        <div class="custom-tree-node" slot-scope="{ node, data }">
                                        <span>
                                            <el-checkbox v-model="data.checked"
                                                         @change="handleCheckChange(data)">{{''}}</el-checkbox>
                                        </span>
                            <span v-if="data.menuName!=null && data.menuName!==''"><el-tag type="success" size="mini">{{data.menuName }}</el-tag></span>
                            <span v-if="data.name!=null &&data.name!==''"><el-tag type="primary" size="mini">{{data.name }}</el-tag></span>
                        </div>
                    </el-tree>
                </el-card>
                <!--       region 原版开始         -->
                <!--                <el-table-->
                <!--                        :data="checkedRoleMenuList"-->
                <!--                        style="width: 100%; margin-bottom: 20px"-->
                <!--                        row-key="id"-->
                <!--                        border-->
                <!--                        default-expand-all-->
                <!--                        :tree-props="{ children: 'sysMenuVos', hasChildren: 'hasSysMenuVos' }"-->
                <!--                        size="medium"-->
                <!--                        :cell-style="{'text-align':'left'}"-->
                <!--                >-->
                <!--                    <el-table-column label="菜单" width="220" align="center">-->
                <!--                        <template slot-scope="scope">-->
                <!--                            <el-checkbox-->
                <!--                                    v-model="scope.row.checked"-->
                <!--                                    @change="changeMenu(scope.row)">-->
                <!--                                {{ scope.row.menuName }}-->
                <!--                            </el-checkbox>-->
                <!--                        </template>-->
                <!--                    </el-table-column>-->
                <!--                    <el-table-column label="权限" width="700" align="center">-->
                <!--                        <template slot-scope="scope">-->
                <!--                            <el-checkbox-->
                <!--                                    v-for="(item, index) in scope.row.sysPermissions"-->
                <!--                                    :key="index"-->
                <!--                                    v-model="item.checked"-->
                <!--                                    @change="changeSysPermissions(item, index, scope.row)">-->
                <!--                                {{ item.name }}-->
                <!--                            </el-checkbox>-->
                <!--                        </template>-->
                <!--                    </el-table-column>-->
                <!--                </el-table>-->
                <!--        endregion 原本结束        -->
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="patchRoleDialogVisible = false">关闭</el-button>
                <el-button type="primary" @click="addRolePermission">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>


    export default {
        data() {
            return {
                loading: false,
                menuStr: "",
                menuPerStr: "",
                keyword: "",
                dialogVisible: false,
                patchRoleDialogVisible: false,
                roleParm: {name: "", page: 1, pageSize: 10, total: 1},
                menuList: [],
                roleMenuList: [],
                checkedRoleMenuList: [],
                roleList: [],
                roleItem: {},
                roleFormData: {
                    id: "",
                    name: "",
                    code: "",
                    roleType: 0,
                    info: "",
                },
                roleTypeList: [
                    {
                        value: 0,
                        label: "函授站（学校）",
                    },
                    {
                        value: 1,
                        label: "教师",
                    },
                    {
                        value: 2,
                        label: "学生",
                    },
                ],
                menuChooseList: [],
                checkBoxList: []
            };
        },
        created() {
            this.querySysRole();
            this.getMenuList();
        },
        methods: {
            handleCheckChange(data) {
                this.getAllChenckList(data, this.checkedRoleMenuList);
                let dimension = data.dimension.split('-');

                if (data.checked) {
                    if (dimension.length > 3) {
                        //勾选了权限
                        if (dimension[3] != null && dimension[3] != '') {
                            this.checkedRoleMenuList[dimension[0]].sysMenuVos[dimension[1]].sysMenuVos[dimension[2]].sysMenuVos[dimension[3]].checked = data.checked;
                        }
                    }
                    if (dimension.length > 2) {
                        //勾选了权限
                        if (dimension[2] != null && dimension[2] != '') {
                            this.checkedRoleMenuList[dimension[0]].sysMenuVos[dimension[1]].sysMenuVos[dimension[2]].checked = data.checked;
                        }
                    }
                    if (dimension.length > 1) {
                        if (dimension[1] != null && dimension[1] != '') {
                            this.checkedRoleMenuList[dimension[0]].sysMenuVos[dimension[1]].checked = true;
                        }
                    }
                    if (dimension.length > 0) {
                        if (dimension[0] != null && dimension[0] != '') {
                            this.checkedRoleMenuList[dimension[0]].checked = true;
                        }
                    }
                }
                // if (data.$menuIndex !== 1) {
                //     this.syncFather(data, this.checkedRoleMenuList);
                // }
                // let nowList = this.$refs['menuTree'].getCheckedKeys();
                // if (node.sysMenuVos != null && node.sysMenuVos.length > 0) {
                // this.getAllChenckList(node.sysMenuVos, nowList);
                // }
                // this.$refs['menuTree'].getCheckedNodes().forEach(node => {
                //     if (node.menuId != null && node.menuId != '') {
                //         this.getTreeNode(this.checkedRoleMenuList, node.menuId);
                //     } else if (node.fatherMenuId != null && node.fatherMenuId != '') {
                //         this.getTreeNode(this.checkedRoleMenuList, node.fatherMenuId);
                //     }
                // });
                // this.menuChooseList.forEach(menu => {
                //     nowList.push(menu.id);
                // });
                // this.$refs['menuTree'].setCheckedKeys(nowList);
            },
            getAllChenckList(c, list) {
                list.forEach(l => {
                    if (l.id === c.id) {
                        // l.checked = true;
                        if (l.sysMenuVos != null && l.sysMenuVos.length > 0) {
                            this.syncChecked(l.sysMenuVos, c.checked);
                        }
                    } else {
                        if (l.sysMenuVos != null && l.sysMenuVos.length > 0) {
                            this.getAllChenckList(c, l.sysMenuVos);
                        }
                    }
                });
            },
            syncChecked(list, checked) {
                list.forEach(l => {
                    this.$set(l, 'checked', checked);
                    if (l.sysMenuVos != null && l.sysMenuVos.length > 0) {
                        this.syncChecked(l.sysMenuVos, checked);
                    }
                })
            },
            syncFather(c, list) {
                list.forEach(l => {
                    if (l.id === c.fatherMenuId || c.menuId === l.id) {
                        this.$set(l, 'checked', true);
                        if (l.fatherMenuId != null && l.fatherMenuId !== '') {
                            this.syncFather(l, this.checkedRoleMenuList);
                        }
                    } else {
                        if (l.sysMenuVos != null && l.sysMenuVos.length > 0) {
                            this.syncFather(c, l.sysMenuVos);
                        }
                    }
                });
            },
            getMenuList() {
                this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                this.openLoadingView();
                this.$axios({
                    url: "hnjxjy-admin/sysPermission/queryAllSysPermissionTwo",
                    headers: this.loginList,
                }).then((res) => {
                    this.loadingView.close();
                    let allmenulist = res.data.data;
                    allmenulist.map((item) => {
                        item.checked = false;
                        return item;
                    });
                    // this.setFatherMenuId(allmenulist);
                    // let newmenulist = that.checkMenuList(allmenulist);
                    // this.menuList = allmenulist;
                    this.checkedRoleMenuList = allmenulist;
                    this.syncSysPermissions(this.checkedRoleMenuList, 1, 0);
                }).catch(() => {
                    this.loadingView.close();
                });
            },
            syncSysPermissions(list, index, dimension) {
                list.forEach((l, $index) => {
                    this.$set(l, 'checked', false);
                    this.$set(l, '$menuIndex', index);
                    if (dimension != 0) {
                        this.$set(l, 'dimension', dimension + $index + '-');
                    } else {
                        this.$set(l, 'dimension', $index + '-');
                    }
                    if (l.sysMenuVos != null && l.sysMenuVos.length > 0) {
                        this.syncSysPermissions(l.sysMenuVos, index + 1, l.dimension);
                    } else if (l.sysPermissions != null && l.sysPermissions.length > 0) {
                        l.sysPermissions.forEach((sys, $sysIndex) => {
                            this.$set(sys, 'sysPermissionsId', sys.id);
                            this.$set(sys, 'dimension', l.dimension + $sysIndex);
                        });
                        this.$set(l, 'sysMenuVos', l.sysPermissions);
                        this.$set(l, 'sysPermissions', []);
                        l.sysMenuVos.forEach(l => {
                            this.$set(l, '$menuIndex', index + 1);
                        });
                    }
                })
            },
            setFatherMenuId(list) {
                list.forEach(a => {
                    if (a.sysMenuVos != null) {
                        a.sysMenuVos.forEach(a1 => {
                            this.$set(a1, 'fatherMenuId', a.id);
                            if (a1.sysMenuVos != null && a1.sysMenuVos.length > 0) {
                                this.setFatherMenuId([a1])
                            }
                        })
                    }
                })
            },
            /**初始菜单默认选中状态 */
            checkMenuList(allmenulist) {
                return this.loopCheckMenuList(allmenulist);
            },
            /**递归循环验证初始化选中状态 */
            loopCheckMenuList(menuList) {
                menuList.forEach((ele) => {
                    ele.checked = false;
                    ele.hasSysMenuVos = false;
                    if (ele.sysPermissions) {
                        ele.sysPermissions.forEach((elePer) => {
                            elePer.checked = false;
                        });
                    }
                    if (ele.sysMenuVos) {
                        ele.sysMenuVos = this.loopCheckMenuList(ele.sysMenuVos);
                    }
                });
                return menuList;
            },
            getRoleMenuList() {
                let roleid = this.roleItem.id;
                this.$axios_supermall.get("hnjxjy-admin/sysPermission/queryAllSysPermission?roleId=" + roleid).then((res) => {
                    this.roleMenuList = res.data.data;
                    this.repliceCheck(this.checkedRoleMenuList);
                    this.loopCheckRoleMenuList(this.checkedRoleMenuList, this.roleMenuList);
                    // let syncRulesList = [];
                    // this.syncRuleList(syncRulesList,this.roleMenuList);
                    // this.syncMenu(this.checkedRoleMenuList,syncRulesList);
                    this.patchRoleDialogVisible = true;
                    this.loading = false;
                });
            },
            syncRuleList(syncRulesList, rules) {
                rules.forEach(r => {
                    syncRulesList.push(r.id);
                    if (r.sysMenuVos != null && r.sysMenuVos.length > 0) {
                        this.syncRuleList(syncRulesList, r.sysMenuVos);
                    }
                    if (r.sysPermissions != null && r.sysPermissions.length > 0) {
                        this.syncRuleList(syncRulesList, r.sysPermissions);
                    }
                })
            },
            syncMenu(menuList, syncRulesList) {
                menuList.forEach(m => {
                    syncRulesList.forEach(s => {
                        if (m.id == s) {
                            m.checked = true;
                        }
                    });
                    if (m.sysMenuVos != null && m.sysMenuVos.length > 0) {
                        this.syncMenu(m.sysMenuVos, syncRulesList);
                    }
                })
            },
            repliceCheck(list) {
                list.forEach(l => {
                    this.$set(l, 'checked', false);
                    if (l.sysMenuVos != null && l.sysMenuVos.length > 0) {
                        this.repliceCheck(l.sysMenuVos);
                    }
                });
            },
            /**递归循环验证是否checked权限菜单 */
            loopCheckRoleMenuList(menuList, roleMenuList) {
                menuList.forEach((ele) => {
                    // ele.checked = false;
                    // ele.hasSysMenuVos = false;
                    roleMenuList.forEach((roleEle) => {
                        if (roleEle.id == ele.id) {
                            ele.checked = true;
                            ele.sysMenuVos.forEach((elePer) => {
                                // elePer.checked = true;
                                if (elePer) {
                                    roleEle.sysPermissions.forEach((roleElePer) => {
                                        if (elePer.sysPermissionsId == roleElePer.id) {
                                            this.$set(elePer, "checked", true);
                                            this.$set(elePer, "demo", true);
                                        }
                                    });
                                }
                            });
                            if (ele.sysMenuVos) {
                                this.loopCheckRoleMenuList(ele.sysMenuVos, roleEle.sysMenuVos);
                            }
                        }
                    });
                });
            },

            /**递归循环添加菜单到string */
            loopAddRoleMenuStr(menuList) {
                menuList.forEach((ele) => {
                    if (ele.checked) {
                        if (ele.menuName!=null && ele.menuName !=''){
                            this.menuStr = this.menuStr + ele.id + ",";
                        }else{
                            this.menuPerStr = this.menuPerStr + ele.id + ",";
                        }
                    }
                    if (ele.sysMenuVos!=null && ele.sysMenuVos.length>0){
                        this.loopAddRoleMenuStr(ele.sysMenuVos);
                    }
                    // ele.sysPermissions.forEach((elePer) => {
                    //     if (elePer.checked) {
                    //         this.menuPerStr = this.menuPerStr + elePer.id + ",";
                    //     }
                    // });
                    // if (ele.sysMenuVos) {
                    //     this.loopAddRoleMenuStr(ele.sysMenuVos);
                    // }
                });
            },

            // 添加按钮
            addGrade() {
                this.title = "创建";
                this.dialogVisible = true;
            },
            // 点击修改
            editor(row) {
                this.title = "修改";
                this.dialogVisible = true;
                this.roleFormData = {
                    id: 0,
                    name: "",
                    code: "",
                    roleType: 1,
                    info: "",
                };
                this.roleFormData = JSON.parse(JSON.stringify(row));
            },
            //显示分配权限弹窗
            showPatchRoleDialog(row) {
                this.loading = true;
                this.roleItem = row;
                // this.patchRoleDialogVisible = true;
                this.getRoleMenuList();
            },

            handleSizeChange(val) {
                this.roleParm.pageSize = val;
                this.querySysRole();
            },
            handleCurrentChange(val) {
                this.roleParm.page = val;
                this.querySysRole();
            },
            querySysRole() {
                this.getPermission('syspermission:list', () => {
                    let that = this;
                    that.roleParm.name = that.keyword;
                    this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                    this.$axios({
                        url: "hnjxjy-admin/sysRole/querySysRole",
                        headers: this.loginList,
                        params: that.roleParm,
                    }).then((res) => {
                        if (res.data.code !== 2000) {
                            // that.$message({
                            //     message: res.data.message,
                            //     type: "warning",
                            // });
                            return;
                        }
                        that.roleParm.total = res.data.data.total;
                        that.roleList = res.data.data.list;
                    });
                });
            },
            addRoleByLoginId() {
                let that = this;
                this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                let opturl = "addRoleByLoginId"; //添加角色

                if (this.roleFormData.id > 0) {
                    opturl = "modifyRoleByRoleId";
                }
                this.$axios
                    .post("hnjxjy-admin/sysRole/" + opturl, that.roleFormData, {
                        headers: that.loginList,
                    })
                    .then((res) => {
                        if (res.data.code !== 2000) {
                            // that.$message({
                            //     message: res.data.message,
                            //     type: "warning",
                            // });
                            return;
                        } else {
                            that.$message({
                                message: res.data.message,
                                type: "warning",
                            });
                            this.dialogVisible = false;
                            that.querySysRole();
                        }
                    });
            },
            deletedRoleByRoleId(row) {
                let that = this;
                this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                let param = new Object();
                param.id = row.id;
                this.$confirm("此操作将永久删除, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        that.$axios
                            .post("hnjxjy-admin/sysRole/deletedRoleByRoleId", param, {
                                headers: that.loginList,
                            })
                            .then((res) => {
                                if (res.data.code !== 2000) {
                                    // that.$message({
                                    //     message: res.data.message,
                                    //     type: "warning",
                                    // });
                                    return;
                                }
                                that.$message({
                                    message: res.data.message,
                                    type: "warning",
                                });
                                that.querySysRole();
                            });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
            changeSysPermissions(item, index, row) {
                // this.checkPmenu(item, this.checkedRoleMenuList, null);
                this.checkPmenuBype(item, this.checkedRoleMenuList, null);
                this.$forceUpdate();
                // this.checkedRoleMenuList.splice();
                // this.checkChildMenu(item, this.checkedRoleMenuList, null);
                // if (item.sysMenuVos == null || item.sysMenuVos.length <= 0) {//说明不是一级权限
                //     if (item.sysPermissions == null) {//权限是空，说明要么是一级菜单要么是三级菜单
                //
                //         if (item.checked == true) {//选中，那么父权限也要选中
                //             row.checked = true;
                //         } else {//取消，那么遍历父权限看是否还有没取消的
                //             // row.sysPermissions.forEach()
                //         }
                //     }
                // } else {//说明是二级权限或者三级权限
                //
                // }
            },
            changeMenu(item) {
                // item.checked = true;
                // this.$set(item,'checked',true)
                this.checkPmenu(item, this.checkedRoleMenuList, null);
                this.checkChildMenu(item, this.checkedRoleMenuList, null);
                this.$forceUpdate();
                // this.checkedRoleMenuList.splice();
            },
            checkChildMenu(item, menulist, pmenu) {
                let that = this;
                menulist.forEach((menu) => {
                    //找到子节点
                    if (item.id == menu.id) {
                        menu.checked = item.checked;
                        if (menu.sysPermissions) {
                            menu.sysPermissions.forEach((elePer) => {
                                elePer.checked = item.checked;
                            });
                        }
                        if (menu.sysMenuVos) {
                            menu.sysMenuVos.forEach((eleMenu) => {
                                eleMenu.checked = item.checked;
                                that.checkChildMenu(eleMenu, menu.sysMenuVos, menu);
                            });
                        }
                    } else {
                        if (menu.sysMenuVos) {
                            menu.sysMenuVos.forEach((eleMenu) => {
                                if (item.id == eleMenu.id) {
                                    eleMenu.checked = item.checked;
                                    that.checkChildMenu(eleMenu, menu.sysMenuVos, menu);
                                }
                            });
                        }
                    }
                });
            },
            checkPmenu(item, menulist, pmenu) {
                menulist.forEach((menu) => {
                    //找到父节点
                    if (item.pmenuId == menu.id) {
                        if (item.checked) {
                            //当前节点父节点选中
                            menu.checked = true;
                            if (pmenu) {
                                pmenu.checked = true;
                            }
                        }
                    }
                    //父节点中1级节点没有，找子节点
                    else {
                        if (menu.sysMenuVos) {
                            this.checkPmenu(item, menu.sysMenuVos, menu);
                        }
                    }
                });
            },
            checkByFather(id) {
                this.checkedRoleMenuList.forEach(l => {
                    if (l.id === id) {
                        l.checked = true;
                        return;
                    } else {
                        l.sysMenuVos.forEach(l1 => {
                            if (l1.id === id) {
                                l1.checked = true;
                                return;
                            } else {
                                l1.sysMenuVos.forEach(l2 => {
                                    if (l2.id === id) {
                                        l2.checked = true;
                                        return;
                                    }
                                })
                            }
                        })
                    }
                })
            },
            checkPmenuBype(item, menulist, pmenu) {
                menulist.forEach((menu) => {
                    if (menu.sysMenuVos != null && menu.sysMenuVos.length > 0) {
                        this.checkPmenuBype(item, menu.sysMenuVos, null);
                    } else {
                        if (menu.sysPermissions != null && menu.sysPermissions.length > 0) {
                            //找到父节点
                            if (item.menuId == menu.id) {
                                if (item.checked) {
                                    //当前节点父节点选中
                                    menu.checked = true;
                                    if (pmenu) {
                                        pmenu.checked = true;

                                    }
                                    if (menu.fatherMenuId != null && menu.fatherMenuId > 0) {
                                        this.checkByFather(menu.fatherMenuId);
                                        // this.checkPmenuBype(menu,this.checkedRoleMenuList, null);
                                    }
                                }
                            }
                            //父节点中1级节点没有，找子节点
                            // else {
                            //     if (menu.sysMenuVos) {
                            //         this.checkPmenu(item, menu.sysMenuVos, menu);
                            //     }
                            // }
                        }
                    }
                });
            },
            addRolePermission() {
                this.menuPerStr = "";
                this.menuStr = "";
                this.loopAddRoleMenuStr(this.checkedRoleMenuList);
                this.menuPerStr = this.menuPerStr.substr(0, this.menuPerStr.length - 1);
                this.menuStr = this.menuStr.substr(0, this.menuStr.length - 1);
                this.loginList = JSON.parse(localStorage.getItem("loginStrage"));
                let parm = new Object();
                parm.id = this.roleItem.id;
                parm.menuIds = this.menuStr;
                parm.permissionIds = this.menuPerStr;
                this.openLoadingView();
                this.$axios_supermall.post("hnjxjy-admin/sysRole/addRoleByMenuIdByPermissionId", parm).then((res) => {
                    this.$message({
                        message: res.data.message,
                        type: "success",
                    });
                    this.patchRoleDialogVisible = false;
                });
            },
        },
    };
</script>
<style scoped>
    .search {
        text-align: left;
        margin-top: 1rem;
        /* margin-left: 1rem; */
        padding: 1rem;
    }

    .table-wrap {
        overflow: auto;
        /* margin-left: 1rem; */
        /* margin-bottom: 1rem; */
        padding: 1rem;
    }

    .demo-ruleForm {
        /* width: 90%; */
        height: calc(100% - 125px);
    }

    .el-form-input-width {
        width: 100%;
    }

    .rigth {
        width: 93%;
        height: 870px;
        overflow-y: scroll;
        display: inline-block;
        /* background-color: #fff; */
        background-color: rgba(164, 174, 224, 0.11);
        float: right;
    }

    .left {
        width: 7%;
        display: inline-block;
    }

    .el-table__row--level-2 td:eq(0) {
        padding-left: 20px;
    }
</style>
